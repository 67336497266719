
import { defineAsyncComponent } from 'vue';
const Checkbox = defineAsyncComponent(() => import('@/components/checkbox.vue'));
import {getChildCategories} from '../services/marketplace-category.services'
export default {
  props: {
    id: {
      default: 0
    },
    categories:{
      default:()=>[]
    },
    searchResultsPaths: {
      required: true,
      default:()=>[],
      type: Array
    },

    level: {
      required: true,
      type: Number
    },
    marketplaceId:{
      type:[String, Number],
      default:()=>''
    },
    categoryTreeCheckAction: {
      required: true,
      type: Function
    },
  },

  components: {
    Checkbox
  },

  name: 'category-tree',
  data() {
    return {
      items: [],
      checkedRootCategories: [],
      parent: '',
      selectedCategories: [],
      loading: true,
    };
  },

  mounted() {
    if(this.level > 0) {
      getChildCategories(this.id,this.marketplaceId).then(res => {
        this.items = res.data.child_categories;
        this.loading = false;
      });
    }
  },

  methods: {
    handleClick(event, cat) {
      this.loading = true;
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();

      // if no childs
      if(cat.has_children === false) {
        return;
      }
      const index = this.selectedCategories.findIndex(id => id === cat.id);
      if(index > -1) {
        this.selectedCategories.splice(index, 1);
      }
      else {
        this.selectedCategories.push( cat.id );
      }
      this.categoryTreeCheckAction(cat, this.level, index>-1)
      this.loading = false
    },
    getClasses(id) {
      return {
        'browse__path--name': true,
        'browse__path--active': this.isAddedAsFilter(id)
      };
    },

    isAddedAsFilter(id) {
      return this.selectedCategories.findIndex(item => item === id) !== -1;
    }
  },

  computed: {

    filteredCategories(){
      if(this.level == 0 ) return this.categories
      return this.items.filter(item=>{
        let matched = false;
        this.searchResultsPaths.forEach(path=>{
          if(
            path
              .toLowerCase()
              .indexOf(item.path_name.toLowerCase()) !== -1
          ) {
            matched =  true
          }
        })
        return matched
      });
    }
  }
};
