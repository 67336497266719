// https://vuex.vuejs.org/en/state.html
import { localStorageGetItem } from '@/utils/local'

const user = localStorageGetItem('user') || {}

export default {
  token: null,
  blockedId:'', // to block specific element in an ajax call
  user:user,
  fetching: false,
  sidebarMenu: true,
  selectedProducts:[],
  notifications:[],
  currentProduct: {},
  headerTitle:'',
  emptyUser: {
    id: '',
    email: '',
    name: '',
    profile_pic: '',
    role: '',
    username: '',
  }
}
