import { isFunction } from 'lodash';
import { default as Products } from './catalog/products/product.store';
import { vuex as CustomAttributes } from './catalog/products/customattributes/store';
import MatchingProducts from './catalog/products/product-details/matching-products/store/matchingProducts.store';
import { vuex as ProductCategories } from '@/app/product-category/store';
import { vuex as MappingTemplates } from './catalog/mapping-template/store';
import { vuex as ProductChangeHistory } from './catalog/products/product-details/historylog/store';
import { vuex as CurrentProduct } from './catalog/products/product-details/store';
import { vuex as ProductImages } from './catalog/products/product-details/product-images/store';
import Companies from './companies/company.store';
import { vuex as Auth } from './auth';
import { vuex as UserProfile } from './user-profile';
import { vuex as configurations } from './configurations';
import { vuex as SetupWizard } from './setupWizard';
import { vuex as Variations } from './catalog/variations/store/vuex';
import { vuex as SizeTable } from './catalog/products/sizetable/store/vuex';
import { vuex as BackgroundJobs } from './backgroundJobs';
import {default as loader} from '@/store/modules/loader'

// start extraction data from vuex modules
const vuex = {
  Products,
  Companies,
  Auth,
  UserProfile,
  configurations,
  SetupWizard,
  CustomAttributes,
  MatchingProducts,
  ProductImages,
  ProductCategories,
  MappingTemplates,
  CurrentProduct,
  ProductChangeHistory,
  Variations,
  SizeTable,
  BackgroundJobs,
  loader,
};

const keys = Object.keys(vuex);

const modules = keys.reduce(
  (acc, key) => ({ ...acc, [key]: vuex[key].module }),
  {}
);
const plugins = keys
  .reduce((acc, key) => [...acc, vuex[key].plugin], [])
  .filter(isFunction);

export default { modules, plugins };
