import * as c from './constants';
import * as api from './api'

export const generateSizeTableImageAction = ({commit}, payload) => {
    commit(c.LOADING);
    return api.generateSizeTableImage(payload).then(res => {
        commit(c.LOADING);
        commit(c.GENERATED);
        return res;
    }, err => {
        commit(c.LOADING);
    })
}

export const addSizeTableValuesAction = ({commit}, payload) => {
    commit(c.LOADING);

    return api.addSizeTableValues(payload).then((res) => {
        commit(c.LOADING);
        const {data} = res;
        if(data.success === true) {
            commit(c.SAVED, true)
            // saved to false after few seconds.
            setTimeout(() => {
                commit(c.SAVED, false)
            }, 1500);

        } else {
            commit(c.SAVED, false)
        }

        return res;
    }, err => {
        commit(c.LOADING);
    })
}

/**
 * 
 * @param object
 * payload.mutiple whether there're multiple products
 * payload.data data to sent to server.
 * @param payload
 */
export const previewSizeTableAction = ({commit}, payload) => {
    commit(c.PREVIEW_LOADING);
    if(payload.multiple) {
        api.bulkPreview(payload.data).then(res => {
            console.log(res)            
            commit(c.PREVIEW_LOADING);
            commit(c.PREVIEW, res.data.size_tables_data);
        }, err => {
            commit(c.PREVIEW_LOADING);
        })
    } else {
        api.singlePreview(payload.data).then(res => {
            commit(c.PREVIEW_LOADING);
            commit(c.PREVIEW, res.data.size_tables_data);
        }, err => {
            commit(c.PREVIEW_LOADING);
        })
    }
}

export const uploadImagesAction = ({commit}, payload) => (
    api.sizeTableImage(payload)
)

export const sizeTableImageAction = ({commit}, payload) => (
    commit(c.SIZE_TABLE_IMAGE, payload)
)

export const setProductsAction = ({commit}, payload) => {
    console.log(1);
    commit(c.SET_PRODUCTS, payload)
}
