class Event{
  events: Object;
  constructor(){
    this.events = {};
  }

  $on(eventName, fn) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  $off(eventName, fn) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }

  $emit(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(function(fn) {
        fn(data);
      });
    }
  }
}

const bus = new Event()

export default function install (app) {
  Object.defineProperties(app.config.globalProperties, {
    $bus: {
      get () {
        return bus
      }
    }
  })
}

