
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapActions } from 'vuex'
import {deleteOutboundTemplate} from './../service/services'
export default {
  data(){
    return {
      items: [],
      loading: false
    };
  },
  mounted(){
    this.getOutBoundList()
  },
  methods: {
    ...mapActions([
      'getTemplates'
    ]),
    async getOutBoundList(){
      try{
        this.loading = true
        let category = 'export'
        let r = await this.getTemplates(category);
        this.items = r.data.templates
        this.loading = false
      }
      catch(err){
        this.loading = false
      }
    },
    handleEdit(item) {
      this.$router.push(`/catalog/mapping-template/outbound/${item.id}/${item.target === 'ChannelAdvisor' ? 'channel_advisor': item.target}`)
    },
    handleDelete(id) {
      ElMessageBox.confirm(
        'Template will be deleted',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            await deleteOutboundTemplate(id);
            this.getOutBoundList();
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          //
        })
    }
  }
}
