import { defineAsyncComponent } from 'vue'
declare let require: any;
/**
 * Components are lazy-loaded - See "Grouping Components in the Same Chunk"
 * http://router.vuejs.org/en/advanced/lazy-loading.html
 */
/* eslint-disable global-require */
const CompaniesList = defineAsyncComponent(() => import('./CompaniesList.vue'));
const CompanyForm = defineAsyncComponent(() => import('./company-form/CompanyForm.vue'));
const CompanyUsers = defineAsyncComponent(() => import('./company-users/CompanyUsers.vue'));

export default [
  {
    name: 'companies.index',
    path: '/companies',
    component: CompaniesList,
  },
  {
    name: 'company.new',
    path: '/company/new',
    component: CompanyForm,
    meta: {
      title: 'Create a new company'
    }
  },
  {
    name: 'company.update',
    path: '/company/update/:id/',
    component: CompanyForm,
    meta: {
      title: 'Update your company'
    }
  },
  {
    name: 'companies.users',
    path: '/company/:id/users',
    component: CompanyUsers,

  }
];
