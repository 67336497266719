import axios from 'axios'
import { BASE_URL } from '@/config/config';

export const http = axios.create({
  baseURL: BASE_URL,
  timeout: 120000 // 2min
});



export default function install(app) {
  Object.defineProperty(app.config.globalProperties, '$http', {
    get() {
      return http
    },
  })
}
