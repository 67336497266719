// https://vuex.vuejs.org/en/actions.html

import * as TYPES from './types'
import { http } from '@/utils/plugins/http'
import state from './state'
import { localStorageSetItem, localStorageSetToken } from '@/utils/local'

export default {
  setFetching({ commit }, obj) {
    commit(TYPES.MAIN_SET_FETCHING, obj)
  },

  setMessage({ commit }, obj) {
    commit(TYPES.MAIN_SET_MESSAGE, obj)
  },
  setSidebarMenu({ commit }, obj) {
    commit(TYPES.MAIN_SET_SIDEBAR_MENU, obj)
  },
  setBulkProducts(_, productIds) {
    state.selectedProducts = productIds
  },
  setToken({ commit }, token) {
    /**
     * Sets the token to the local storage
     * for auto-login purpose
     */
    localStorageSetToken(token)
    /**
     * Commit the mutation
     */
    commit(TYPES.MAIN_SET_TOKEN, token)
  },

  setUser({ commit }, user) {
    /**
     * Sets the user to the local storage
     * for auto-login purpose
     */
    if (Object.keys(user).length === 0) localStorage.removeItem('activeCompany')

    localStorageSetItem('user', user)
    commit(TYPES.MAIN_SET_USER, user)
  },
  getNotifications({ commit }, obj) {
    http
      .get(
        `companies/${obj.company_id}/notifications?per_page_count=${obj.per_page_count}&page_no=${obj.page_no}`
      )
      .then((res) => {
        commit(TYPES.COMPANIES_NOTIFICATIONS, res)
        return res
      })
  },
  setCurrentProduct({ commit }, obj) {
    commit(TYPES.CURRENT_PRODUCT, obj)
  },

  setHeaderTitle({ commit }, title) {
    commit(TYPES.MAIN_SET_HEADER_TITLE, title)
  },
}
