import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd9a90f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mapping-tpl-manage-header" }
const _hoisted_2 = { class: "heading-text" }
const _hoisted_3 = { class: "category-box" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "mapping-tbl-action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString($props.heading), 1),
      _createElementVNode("div", _hoisted_3, [
        (!$props.selectedCategory)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, "Select Category"))
          : (_openBlock(), _createElementBlock("label", _hoisted_5, "Review, change and save mapping"))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_button, {
        plain: "",
        onClick: $options.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, {
        type: "primary",
        disabled: !$props.selectedCategory,
        onClick: $options.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Save")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}