import { default as products } from './catalog/products/product.routes'
import { routes as configurations } from './configurations'
import { routes as auth_Routes } from './auth'
import dashboard from '@/app/dashboard/dashboard.routes'
import productMappingTemplate from '@/app/catalog/mapping-template/mappingTemplate.routes'
import companies from './companies/company.routes'
import { routes as setupWizard } from './setupWizard'
import { routes as userProfile } from './user-profile'
import { routes as backgroundJobs } from './backgroundJobs'



export const adminRoutes = [
  ...products,
  ...productMappingTemplate,
  ...configurations,
  ...companies,
  ...userProfile,
  ...setupWizard,
  ...dashboard,
  ...backgroundJobs,
]


export const authRoutes = [
  ...auth_Routes,
]
