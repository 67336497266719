
export default {
  props: {
    heading: {
      type: String,
    },
    selectedCategory: null,
  },
  data() {
    return {
      catalogSource: null,
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'products.mapping-template-inbound' })
    },
    save() {
      this.$emit('save')
    },
  },
}
