import * as services from '../service/services';

import { EDIT_VARIATION, RESET_VARIATION, VARIATION_REMOVED } from './constants';

export const editVariationAction = ({commit}, payload) => (
    commit(EDIT_VARIATION, payload)
)

export const resetVariationAction = ({commit}) => (
    commit(RESET_VARIATION)
)

export const variationRemovedAction = ({commit}, payload) => (
    commit(VARIATION_REMOVED, payload)
)