import { defineAsyncComponent } from 'vue';
declare let require: any
/**
* Components are lazy-loaded - See "Grouping Components in the Same Chunk"
* http://router.vuejs.org/en/advanced/lazy-loading.html
*/
/* eslint-disable global-require */
const Dashboard = defineAsyncComponent(() => import('./Dashboard.vue'));

const meta = {
  headerTitle:'Dashboard'
}

export default [
  {
    name: 'dashboard.index',
    path: '/',
    component: Dashboard,
    meta

  },
  {
    name: 'dashboard.index.home',
    path: '/dashboard',
    component: Dashboard,
    meta
  }

];
