
import { defineAsyncComponent } from 'vue'
import CategoryTree from './CategoryTree.vue'
const Checkbox = defineAsyncComponent(() => import('@/components/checkbox.vue'));
export default {
  components:{
    CategoryTree,
    Checkbox
  },
  props: {
    searchResults: {
      type: Array,
      default: ()=>[]
    },
    searchRoots: {
      type: Array,
      default: ()=>[]
    },
    marketplaceId:{
      type:[String, Number],
      default:()=>''
    },
    searchLoading:{
      type:Boolean,
      default:()=>false
    }
  },
  data(){
    return {
      searchTerm:'',
      selectedCategoriesPaths:[],
    }
  },
  computed:{
    filteredSearchRoots(){
      if(!this.searchRoots) return []
      return this.searchRoots.filter((item) => {
        return item.path_name.toLowerCase().indexOf(this.searchTerm) !== -1;
      });
    },
    searchResultsPaths(){
      if(!this.searchResults) return
      return this.searchResults.map(item => item.path_name);
    },
    filteredSearchResults(){
      if(!this.searchResults) return []
      if(this.selectedCategoriesPaths.length == 0) return this.searchResults

      let filteredSelectedCategoriesPaths = [...this.selectedCategoriesPaths].filter(cp=>{ //clone selectedCategoriesPaths
        for(let i=0; i<this.selectedCategoriesPaths.length; i++){
          let cp1 = this.selectedCategoriesPaths[i]
          let cp2 = cp.includes('>')?cp:cp+' > ';//add > to root level categories
          if(cp1 != cp && cp1.indexOf(cp2) == 0) {
            return false;
          }
        }
        return true
      })
      let results = []
      this.searchResults.forEach(r=>{
        for(let i=0; i<filteredSelectedCategoriesPaths.length; i++){
          let cp = filteredSelectedCategoriesPaths[i]
          if(r.path_name.indexOf(cp) == 0 ) {
            results.push(r)
            break
          }
        }
      })
      return results
    }
  },
  methods: {
    categoryTreeCheckAction(cat){
      let path_name = cat.path_name.trim()
      let index = this.selectedCategoriesPaths.findIndex(path => path == path_name)
      if(index > -1) {
        //remove the path and all the children
        let toRemove = [index]
        let parent_path = path_name.includes('>')?path_name:path_name+' > ';
        this.selectedCategoriesPaths.forEach((p,i)=>{
          if(i != index && p.indexOf(parent_path) == 0) {
            toRemove.push(i)
          }
        })
        this.selectedCategoriesPaths = this.selectedCategoriesPaths.filter((p,i)=>toRemove.indexOf(i)==-1)
      }
      else {
        this.selectedCategoriesPaths.push(path_name)
      }
    },
    categorySelected(category){
      this.$emit('categorySelected', category)
    },
    clearFields(){
      this.selectedCategoriesPaths = []
    }
  }
}
