
import { Check, OfficeBuilding } from '@element-plus/icons-vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Check,
    OfficeBuilding,
  },
  props: {
    currentCompany: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: { companyChanged: null },
  data() {
    return {
      companies: [],
    }
  },
  mounted() {
    this.getCompanies()
      .then((r) => {
        this.companies = r.companies
      })
      .catch((err) => {
        console.error(`Failed to get companies: ${err}`)
      })
  },
  methods: {
    ...mapActions(['getCompanies']),
    handleChangeCompany(id) {
      this.$emit('companyChanged', id)
    },
  },
}
