
import { mapActions, mapState } from 'vuex'
import { ArrowRight } from '@element-plus/icons-vue'
import graphql from '@/graphql';
import ProductCategorySelector from '@/components/product-category-selector/ProductCategorySelector.vue'
import CategoryMappingTemplate from '@/app/catalog/mapping-template/components/CategoryMappingTemplate.vue'
import { Market, Marketplace } from '@/app/catalog/interfaces'
import { formatters } from '@/utils/common/sources'
import MappingFieldsForm from './mapping-template-manage-form/MappingFieldsForm.vue'
import MappingTemplateManageHeader from './MappingTemplateManagerHeader.vue'

let iconMap = {
  all: 'el-icon-notebook-2',
  // images: 'el-icon-picture-outline',
  // variation: 'el-icon-sort',
  // basic: 'el-icon-s-operation',
  // discovery: 'el-icon-search',
  // product_enrichment: 'el-icon-monitor',
  // dimensions: 'el-icon-copy-document',
  // fulfillment: 'el-icon-goods',
  // compliance: 'el-icon-document-copy',
  // offer: 'el-icon-present',
  // b2b: 'el-icon-suitcase'
}

interface InboundMappingTemplateManageData {
  mappingSourceMarket: Market
  mappingSourceMarketplace: Marketplace
  loading: boolean
  iconMap: any
  selectedCategory: any
  category: { name: string; path: string; id: number }
  newMappings: object
}

export default {
  components: {
    ProductCategorySelector,
    MappingFieldsForm,
    MappingTemplateManageHeader,
    CategoryMappingTemplate,
    ArrowRight,
  },
  provide: {
    marketplaceSelectionDisable: true,
    catalogSourceSelectionDisable: true,
  },
  emits: { cancel: null },
  data: (): InboundMappingTemplateManageData => {
    return {
      loading: false,
      iconMap,
      selectedCategory: null,
      category: { id: 0, name: '', path: '' },
      newMappings: {},
      mappingSourceMarket: null,
      mappingSourceMarketplace: null,
    }
  },
  apollo : {
    sources : {
      query  : graphql.query.GetSources,
      result ({ data }) {
        const sources = data?.sources;
        const marketplaceId = this.$route.params.marketplaceId;
        if(sources && marketplaceId) {
          this.mappingSourceMarket = formatters.sourceByMarketplaceId(sources, marketplaceId);
          this.mappingSourceMarketplace = formatters.marketplaceById(sources, marketplaceId);
        }
      },
      watchLoading(loading) {
        this.loading = loading;
      }
    },
    amazon_category : {
      query : graphql.query.GetAmazonCategory,
      variables () {
        return {
          categoryId : +this.$route.params.id
        }
      },
      result({data}) {
        this.onCategorySelected({
          category_name: data.amazon_category.browse_node_name,
          category_id: +data.amazon_category.id,
          category_path: data.amazon_category.browse_path_by_name,
          marketplace_id: +this.$route.params.marketplaceId,
        })
      },
      watchLoading(loading) {
        this.loading = loading;
      },
      skip() {
        return !this.$route.params.id;
      },
      error(error) {
        console.error(error)
        this.$router.replace('/catalog/mapping-template/inbound')
      }
    }
  },
  computed: {
    ...mapState({
      templates: (state) => {
        return state['MappingTemplates'].templates
      },
    }),
    currentlyEditedTemplate() {
      return this.templates[this.selectedCategory]
    },
    heading() {
      return `${this.mappingSourceMarket?.name} ${this.mappingSourceMarketplace?.locale_code} to eComPIM category mapping`
    },
  },
  methods: {
    onSaveTemplate(): void {
      if (
        !this.newMappings[this.selectedCategory] ||
        !Object.keys(this.newMappings[this.selectedCategory]).length
      )
        return
      this.saveTemplate({
        category_id: this.selectedCategory,
        marketplace_source_id: 1,
        target_id: 'ecompim',
        newFieldsMapping: this.newMappings[this.selectedCategory],
      })
        .then(() =>
          this.$message({
            type: 'success',
            message: 'Template successfully saved',
          })
        )
        .catch((errorMessage) => {
          this.$message({
            type: 'error',
            message: errorMessage,
          })
        })
        .finally(() => {
          this.$router.push('/catalog/mapping-template/inbound')
        })
    },
    ...mapActions(['getTemplate', 'saveTemplate']),

    onCategorySelected({
      category_id,
      category_name,
      category_path,
      marketplace_id,
    }) {
      this.selectedCategory = category_id
      this.getTemplate({
        marketplace: marketplace_id,
        category_id,
      })
      ;[this.category.id, this.category.name, this.category.path] = [
        category_id,
        category_name,
        category_path,
      ]
    },
    cancel() {
      this.$emit('cancel')
    },
    onNewMappingConfigured({ fromField, newToField }) {
      if (!this.newMappings[this.selectedCategory])
        this.newMappings[this.selectedCategory] = {}
      this.newMappings[this.selectedCategory][fromField] = newToField
    },
  },
}
