import { defineAsyncComponent } from 'vue'
declare let require: any
/**
* Components are lazy-loaded - See "Grouping Components in the Same Chunk"
* http://router.vuejs.org/en/advanced/lazy-loading.html
*/

/* eslint-disable global-require */
const UserProfile = defineAsyncComponent(() => import('./UserProfile.vue'))
const UserProfileDetails = defineAsyncComponent(() => import('./UserProfileDetails.vue'))
const Picture = defineAsyncComponent(() => import('./picture.vue'))

export default [
  {
    name: 'user',
    path: '/user',
    component: UserProfile,

    children: [
      {
        name: 'user.index',
        path: '/user/profile',
        component: UserProfileDetails,

      },
      {
        name: 'user.picture',
        path: '/user/picture',
        component: Picture,

      }
    ]
  }
]