
import SuggestedCategories from './tabs/SuggestedCategories.vue'
import TopCategoriesList from './tabs/TopCategoriesList.vue'
import BrowseCategories from './tabs/BrowseCategories.vue'
import CategorySearchResults from './product-category-search-results/CategorySearchResults.vue'
import ProductCategorySelectorHeader from './product-category-selector-header/ProductCategorySelectorHeader.vue'
import {
  getCategorySuggestions,
  getTopCategories,
  getProductCategorySuggestions,
  getRootCategories,
} from './services/marketplace-category.services'

export default {
  components: {
    TopCategoriesList,
    SuggestedCategories,
    BrowseCategories,
    CategorySearchResults,
    ProductCategorySelectorHeader,
  },
  props: {
    hideSuggestionTab: {
      type: Boolean,
      default: false,
    },
    defaultCatalogSourceId: {
      type: Number,
      default: 1,
    },
    defaultCatalogSourceMarketplaceId: {
      type: Number,
    },
    defaultActiveTab: {
      type: String as () =>
        | 'top_categories'
        | 'browse_categories'
        | 'suggested-categories',
      default: () => 'top_categories',
    },
  },
  emits: ['categorySelected'],

  data() {
    return {
      selected: {
        source_id: '',
        marketplace_id: '',
      },
      activeName: this.defaultActiveTab,
      searchResults: [],
      searchRoots: [],
      topCategories: [],
      productSuggestedCategories: [],
      rootCategories: [],
      loading: false,
      searchLoading: false,
      showSearchBox: false,
      tabs_requires_to_fetch: {
        top_categories: true,
        categories_suggestions: true,
        browse_categories: true,
      },
    }
  },
  async mounted() {
    this.product_id = this.$route.params.id
  },
  methods: {
    categorySelected(category) {
      this.$emit(
        'categorySelected',
        category,
        this.selected.source_id,
        this.selected.marketplace_id
      )
    },
    async marketPlaceChange(source_id, marketplace_id) {
      this.selected.source_id = source_id
      this.selected.marketplace_id = marketplace_id
      this.setTabsFetchStatus()
      this.$refs.cat_selector_header.clearInputField()
      await this.fetchTabData(source_id, marketplace_id)
    },

    async tabChanged() {
      if (this.tabs_requires_to_fetch[this.activeName]) {
        await this.fetchTabData()
      }
    },
    async fetchTabData() {
      if (this.selected.source_id == '' || this.selected.marketplace_id == '')
        return
      this.loading = true
      if (this.activeName == 'top_categories') {
        await getTopCategories(this.selected.source_id).then((response) => {
          this.topCategories = response.data.top_categories
        })
      } else if (this.activeName == 'categories_suggestions') {
        await getProductCategorySuggestions(
          this.product_id,
          this.selected.marketplace_id
        ).then((response) => {
          this.productSuggestedCategories =
            response.data.product_category_suggestions
        })
      } else if (this.activeName == 'browse_categories') {
        this.rootCategories = null //this rebuilds the child component BrowseCategory(v-if in template). so no need to keep watchers on the child
        await getRootCategories(this.selected.marketplace_id).then(
          (response) => {
            this.rootCategories = response.data.root_categories
          }
        )
      }
      this.loading = false
      this.tabs_requires_to_fetch[this.activeName] = false
    },
    async searchTermDebounce(term) {
      if (term) {
        this.loading = this.searchLoading = true
        await getCategorySuggestions(term, this.selected.marketplace_id).then(
          (response) => {
            this.$refs.csr.clearFields()
            this.searchResults = response.data.categories_suggestions.categories
            this.searchRoots =
              response.data.categories_suggestions.top_parent_categories
            this.showSearchBox = true
            this.loading = this.searchLoading = false
          }
        )
        this.loading = this.searchLoading = false
      } else {
        this.showSearchBox = false
        this.searchResults = []
        this.searchRoots = []
      }
    },
    searchTermChange(term) {
      if (term) {
        this.showSearchBox = true
      } else {
        this.showSearchBox = false
      }
    },
    setTabsFetchStatus() {
      for (var prop in this.tabs_requires_to_fetch) {
        this.tabs_requires_to_fetch[prop] = true
      }
    },
  },
}
