import { http } from '@/utils/plugins/http'

export const removeProductAttribute = (id, key) => {
  return http.delete(`/products/${id}/destroy_attributes`, {
    data: {
      keys: [key]
    }
  })
}

export const archiveProduct = (obj) => http.post(`products/archive`, obj)

export const getProductFields = () => http.get('/search_filters/fetch_attributes')

export const deleteProduct = (id) => http.delete(`products/${id}`)

export const matchOnAmazon = (obj) => http.post(`products/search_on_amazon`, obj)

export const matchOnEbay = (obj) => http.post(`products/search_on_ebay`, obj)

export const getProducts = ({ currentPage, limit, savedSearchId, sortingColumn, sortingOrder }) => http.get(`/products?page_no=${currentPage}&per_page_count=${limit}&saved_search_id=${savedSearchId}&sorting_column=${sortingColumn}&sorting_order=${sortingOrder}`)

// export const saveProduct = (payload) => http.post('/products', payload)

export const replaceInProducts = (obj) => http.put('/products/find_and_replace', obj)

// export const getProductImages = (id) => http.get(`/images?product_id=${id}`)

// export const deleteProductImage = (id) => http.delete(`images/${id}`)

// export const getImageSuggestions = ({ searchBy, searchValue, src }) => http.get(`images/suggestion?search_by=${searchBy}&search_value=${searchValue}&src=${src}`)

// export const addImageFromUrl = (payload) => http.post('images/create_from_url', payload)

// export const uploadProductImage = (payload) => http.post('images', payload)

export const importProducts = (payload) => http.post(`companies/${payload.company_id}/background_job_configurations?job_type=` + payload.job_type)

export const deleteImportRecord = (id) => http.delete(`imports/${id}`)

export const editImportRecord = ({ id, payload }) => http.put(`imports/${id}`, payload)

export const getImportcatalogSettings = (id) => http.get(`/companies/${id}/ca_import_catalog_settings`)


export const getImportRecords = (obj) => http.get(`/background_jobs?page_no=${obj.currentPage}&per_page_count=${obj.limit}&saved_search_id=${obj.savedSearchId}&sorting_column=${obj.sortingColumn}&sorting_order=${obj.sortingOrder}&catalog_source_id=${obj.catalog_source_id}`, {
  params: {
    history_type: obj.history_type
  }
})

/* get matching products of amazon. */
// export const getMatchingProducts = (ids) => http.post(`products/get_matched_products`, { ids })

// export const MatchingProducts = (ids) => http.post(`products/get_ebay_matched_products`, { ids })

export const saveCatlogSettings = (obj) => http.post(`companies/${obj.id}/ca_import_catalog_settings?include_images=` + obj.include_images + '&include_labels=' + obj.include_labels + '&include_attributes=' + obj.include_attributes + '&include_dc_quantities=' + obj.include_dc_quantities + '&quantity_greater_than=' + obj.quantity_greater_than)

// const getProductImageSuggestions = (productId) => http.get(`products/${productId}/image_suggestions`)

/***************Product Categories****************/
// export const syncImage = (id) => http.post(`/products/${id}/sync_images`)

export const getCategories = (productId) => http.get(`/products/${productId}/categories`)

export const getAmazonRootCategories = (marketplace) => http.get(`amazon/categories/root_categories?marketplace_code=${marketplace}`)

export const getEbayRootCategories = (id) => http.get(`ebay/categories/root_categories?ebay_site_code=${id}`)

export const getEbaySiteCodes = () => http.get('ebay/categories/ebay_site_codes')

export const getEbayChildCategories = (id) => http.get(`ebay/categories/${id}/child_categories`)

export const browseByNodeId = (id) => http.get(`amazon/categories/get_by_browse_node_id?browse_node_id=${id}`)

export const getAmazonChildCategories = (id) => http.get(`amazon/categories/${id}/child_categories`)

export const productsBulkUpdate = (payload) => http.put(`/products/bulk_update`, payload)
/***************Product Categories****************/

/*************** CUSTOM ATTRIBUTE SUGGESSTIONS **********/
export const getCustomAttrSuggesstions = ({ company_id, search_term }) => {
  return http.get(`/companies/${company_id}/extra_attributes_suggestions`, {
    params: {
      search_term: search_term
    }
  })
}

export const getCustomAttrs = (company_id) => {
  return http.get(`/companies/${company_id}/extra_attributes_suggestions`)
}


export const getExtraAttrValueSuggesstions = ({ company_id, extra_attribute_key }) => {
  return http.get(`/companies/${company_id}/extra_attribute_value_suggestions`, {
    params: {
      extra_attribute_key
    }
  })
}


export const getSearchByParents = (term, site_code, parent_category_ids = []) => {
  return http.post('categories/search_by_parents', { parent_category_ids }, {
    params: {
      term,
      site_code,
    }
  })
}

// export const getEbaySpecifics = (id) => http.get(`ebay/categories/${id}/category_specifics`)

export const getProductCategorySuggestions = (id, site_code) => {
  return http.get(`products/${id}/category_suggestions`, {
    params: {
      site_code
    }
  })
}

export const getCoreColumns = () => (
  http.get('/products/core_columns')
)

export const exportCsv = (id, selected_columns, payload) => {
  let url = '/products/export_csv'
  if (id !== null) {
    url += `?saved_search_id=${id}&current_selected_columns=${selected_columns}`
  } else {
    url += `?current_selected_columns=${selected_columns}`
  }
  if (payload !== null) {
    return http.post(url, payload)
  } else {
    return http.post(url)
  }
}

export const createProductByFile = (payload) => (
  http.post('/products/create_products_by_file', payload)
)

export const downloadDefaultTemplate = () => (
  http.post('/products/default_template')
)
