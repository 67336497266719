import * as SourcesQueries from './sources'
import * as MappingTemplateQueries from './mappingTemplate'
import * as ProductCategoryQueries from './productCategory'
import * as CatalogProductsSearchStatesQueries from './catalogProductsSearchState'
import * as CatalogProductQueries from './catalogProduct'
export default {
  query: {
    ...SourcesQueries,
    ...MappingTemplateQueries,
    ...ProductCategoryQueries,
    ...CatalogProductsSearchStatesQueries,
    ...CatalogProductQueries
  }
};