//const SizeTableImage  =defineAsyncComponent(() => import('./sizetable/views/sizetable.vue');
//const SizeTableProducts = defineAsyncComponent(() => import('./sizetable/views/size-table-products.vue');
import { defineAsyncComponent } from 'vue'


const CatalogProducts = defineAsyncComponent(() => import('./products-grid/CatalogProducts.vue'));
const Form = defineAsyncComponent(() => import('./product-details/basic-fields/ProductBasicFields.vue'));
const Item = defineAsyncComponent(() => import('./product-details/product-details-main/ProductDetailsMain.vue'));
const ImportExport = defineAsyncComponent(() => import('./import-export/index.vue'));

const ProductImages = defineAsyncComponent(() => import('./product-details/product-images/ProductImages.vue'));
const ProductImagesList = defineAsyncComponent(() => import('./product-details/product-images/product-images-list/ProductImagesList.vue'));
const ImageUpload = defineAsyncComponent(() => import('./product-details/product-images/product-images-upload/ProductImagesUpload.vue'));
const ImageSuggestion = defineAsyncComponent(() => import('./product-details/product-images/product-images-suggestion/ProductImagesSuggestion.vue'));
const ProductCategoriesList = defineAsyncComponent(() => import('./product-details/product-categories-list/ProductCategoriesList.vue'));

const MatchingProducts = defineAsyncComponent(() => import('./product-details/matching-products/MatchingProducts.vue'));
const customAttributes = defineAsyncComponent(() => import('./customattributes/views/customAttributes.vue'));
const historyLog = defineAsyncComponent(() => import('./product-details/historylog/views/historyLog.vue'));
const Variations = defineAsyncComponent(() => import('@/app/catalog/variations/views/variations.vue'));
const CustomExport = defineAsyncComponent(() => import('./custom-export/index.vue'));

const BulkUpdateCategory = defineAsyncComponent(() => import('./bulk-update/bulk-update-category/BulkUpdateCategory.vue'));

export default [
  // {
  //   name: "sizetable.products",
  //   path: "/catalog/sizetable/products",
  //   component: SizeTableProducts
  // },
  // {
  //   name: 'sizetable',
  //   path: '/catalog/sizetable/:id',
  //   component: SizeTableImage,

  // },
  {
    name: 'variations',
    path: '/catalog/variations',
    component: Variations,

  },
  {
    name: 'products.index',
    path: '/catalog/products',
    component: CatalogProducts,
    meta: {
      headerTitle: 'Products'
    }
  },
  {
    name: 'products.import-export',
    path: '/catalog/products/import-export',
    component: ImportExport,
  },
  {
    name: 'products.custom-export',
    path: '/catalog/products/custom-export',
    component: CustomExport,

  },
  {
    name: 'products.bulk-update-category',
    path: '/catalog/products/bulk-update-category',
    component: BulkUpdateCategory,
  },
  {
    name: 'products.item',
    path: '/catalog/products/item',
    component: Item,


    children: [
      {
        name: 'products.new',
        path: 'create/:type(parent)?',
        component: Form,
      },
      {
        name: 'products.edit',
        path: ':id/edit',
        component: Form,

      },
      {
        name: 'products.categories',
        path: ':id/categories',
        component: ProductCategoriesList,

      },
      {
        name: 'products.matchingProducts',
        path: ':id/matchingProducts',
        component: MatchingProducts,

      },
      {
        name: 'products.customAttributes',
        path: ':id/customAttributes',
        component: customAttributes,

      },
      {
        name: 'products.log',
        path: ':id/log',
        component: historyLog,

      },
      {
        name: 'products.images',
        path: ':id/images',
        component: ProductImages,

        children: [
          {
            name: 'products.images.list',
            path: 'list',
            component: ProductImagesList,

          },
          {
            name: 'products.images.upload',
            path: 'upload',
            component: ImageUpload,

          },
          {
            name: 'products.images.suggestion',
            path: 'suggestion',
            component: ImageSuggestion,

          }
        ]
      }
    ]
  }
]
