
import MarketMappingTemplatesList from './MarketMappingTemplatesList.vue'

export default {
  components: {
    MarketMappingTemplatesList,
  },
  props: {
    index: {
      type: Number,
    },
    row: {
      type: Object,
    },
    toMapping: {
      type: Object,
    },
  },
  emits: ['onDeleteMappingTemplate'],

  data: () => {
    return {
      isExpanded: false,
    }
  },
  computed: {
    arrowIcon() {
      return !this.isExpanded ? 'angle-down' : 'angle-up'
    },
  },
  methods: {
    addMappingTemplate() {
      this.$router.push({
        name: 'products.mapping-template-inbound.create',
        params: { type: 'inbound', marketplaceId: this.row.marketplace.id },
      })
    },
    editMappingTemplate(category_id) {
      this.$router.push({
        name: 'mapping-template.edit-inbound',
        params: { id: category_id, type: 'inbound', marketplaceId: this.row.marketplace.id },
      })
    },
    toggleCollapse() {
      this.isExpanded = !this.isExpanded
    },
  },
}
