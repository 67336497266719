import moment from 'moment'

function snakeToCamelCase(value) {
  const captialValues = ['asin', 'ean', 'mpn', 'isbn', 'upc', 'sku'];
  if (captialValues.indexOf(value) > -1) {
    value = value.toUpperCase();
  }
  return value.split('_').map(function (item) {
    return item.charAt(0).toUpperCase() + item.substring(1)
  }).join(' ')
}
function snakeToCapital(value) {
  return value.split('_').map(function (item) {
    return item.toUpperCase()
  }).join(' ')
}
function standardDateFormat(value) {
  if (value)
    return moment(value).format('MM/DD/YYYY hh:mm A');
  else
    return '';
}
export const filters = {
  snakeToCamelCase,
  snakeToCapital,
  standardDateFormat
};