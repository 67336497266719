import { http } from '@/utils/plugins/http'

export const saveProductAttribute = (extra_attributes, id) => http.post(`products/${id}/add_attributes`, extra_attributes)

export const getcatalogSources = () =>  http.get('catalog_sources')

export const removeProductAttribute = (id, key) => {
	return http.delete(`/products/${id}/destroy_attributes`, {
		data: {
			keys: [key]
		}
	})
}


export const getCustomAttrSuggesstions = ({company_id, search_term}) => {
	return http.get(`/companies/${company_id}/extra_attributes_suggestions`, {
		params: {
			search_term: search_term
		}
	})
}
export const getExtraAttrValueSuggesstions = ({company_id, extra_attribute_key}) => {
	return http.get(`/companies/${company_id}/extra_attribute_value_suggestions`, {
		params: {
			extra_attribute_key
		}
	})
}

export const getSearchByParents = (term, site_code, parent_category_ids = []) => {
	return http.post('categories/search_by_parents', {parent_category_ids}, {
		params: {
			term,
			site_code,
		}
	})
}

export const getEbaySpecifics = (id) => http.get(`ebay/categories/${id}/category_specifics`)

export const getProductCategorySuggestions = (id, site_code) => {
	return http.get(`products/${id}/category_suggestions`, {
		params: {
			site_code
		}
	})
}
