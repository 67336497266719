import { http } from '@/utils/plugins/http'
import {apolloClient as gclient} from '@/utils/vue-apollo'
import { gql} from 'graphql-tag'

export const getAttributes = () => http.get('/catalog_sources');

export const getImageAbbrivation = (id) => http.get(`/companies/${id}/image_placements`);

export const saveImgAbbr = (obj) => http.put(`/companies/${obj.company_id}/image_placements`, { image_placement: obj.image_placement });

export const getConfigurations = (id) => http.get('/configurations?catalog_source_id=' + id);

export const updateInterval = (obj) => http.put(`/companies/${obj.company_id}/background_job_configurations/update_interval?job_type=` + obj.job_type + '&interval=' + obj.interval);
export const getAvailAbleIntervals = (obj) => http.get(`/companies/${obj.company_id}/background_job_configurations/available_intervals?job_type=` + obj.job_type);

export const configurations = (obj) => http.put('/configurations', obj);

export const saveRequestAccess = (obj) => http.post(`/companies/${obj.company_id}/ca_request_access?channeladvisor_profile_id=` + obj.channeladvisor_profile_id);

export const saveEbayToken = (obj) => http.post(`/companies/${obj.id}/authenticate_ebay`, { code: obj.code });

export const getCaStatus = (obj) => http.get(`/companies/${obj.company_id}/ca_status`);
// export const saveImgAbbr = ({ obj }) => http.put(`/companies/${obj.company_id}/image_placements`, obj.image_placement);

export const getEbayAuthenticationUrl = () => http.get('/companies/ebay_authentication_url');
export const getFTPCredentialStatus = () => http.get('/companies/ca_ftp_status');

export const updateCaFtpSftpFolders = (obj) => http.put(`/configurations/${obj.configuration.id}/update_ca_account_credentials`,obj);

export const createCaFtpSftpCreadentials = (obj) => http.post(`/configurations/${obj.configuration.id}/create_sftp_credentials`,obj);

export const getCaFolders = () =>{
  return gclient.query({
    query:gql`query {
      active_company {
        ca_sftp_folders_list
      }
		}`,
    fetchPolicy: 'no-cache'
  })
}