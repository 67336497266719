<template>
  <div class="info-box">
    <slot name="icon">
      <el-unicon
        class="plus-icon"
        fill="#008824"
        name="info-circle"
        width="16px"
      />
    </slot>
    <span>
      <slot />
    </span>
    <slot name="link"> </slot>
  </div>
</template>
<style scoped>
/* .mapping-template-row{
        margin-bottom: -20px;
    } */
.info-box {
  display: inline-block;
  background: #01c03408;
  padding: 14px 12px;
  border-radius: 7px;
  font-size: 14px;
  border: 1px solid #01c0341f;
}
.info-box span {
  color: #008824;
  margin-left: 10px;
}
.info-box a {
  color: #17b7ff;
}
</style>
