import * as services from '../service/services'

const actions = {
  /**
   * Queries the categories over API and sets in the store.
   */
  getCustomAttrSuggesstions({ commit, rootState }, search_term) {
    const company_id = rootState.Companies.currentCompany.company.id
    return services
      .getCustomAttrSuggesstions({ search_term, company_id })
      .then((res) => {
        return res
      })
  },

  getExtraAttrValueSuggesstions({ commit, rootState }, extra_attribute_key) {
    const company_id = rootState.user.activeCompanyId
    return services
      .getExtraAttrValueSuggesstions({ company_id, extra_attribute_key })
      .then((res) => {
        return res
      })
  },
  saveProductAttribute({ dispatch }, { extra_attributes, id }) {
    return services
      .saveProductAttribute(extra_attributes, id)
      .then((response) => {
        return response
      })
  },
  removeProductAttribute({ dispatch }, obj) {
    return services.removeProductAttribute(obj.id, obj.key).then((response) => {
      return response
    })
  },
}

export default {
  module: {
    actions,
  },
}
