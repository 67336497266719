import * as TYPES from '@/store/types'
import * as services from './product.services'

const state = {
  triggerstatus: false,
  eLoadingCloseStatus: false,
  pagination: {},
  images: {},
  imports: [],
  column_customizations: [],
  imageSuggestions: {
    searchValue: '',
    searchBy: '',
    src: '',
    images: null
  },
  // matchingProducts: [],
  categories: [],//list of categories summary for current product

  // created not to affect previous category data while creating
  // new category feature.
  categoryState: {
    rootCategories: {
      amazon: [], // fetch and post.
      ebay: [],
      // other must be created on needed.
    }
  },

  jobId: null
}

const mutations = {

  [TYPES.PRODUCTS_IMPORT_SET_DATA](_state, obj) {
    _state.imports = obj.imports
    _state.pagination = obj.pagination
  },

  [TYPES.PRODUCTS_IMPORT_REMOVE_DATA](_state, id) {
    const imports = [].concat(_state.imports)
    _state.imports = imports.filter((item) => {
      return item.id !== id
    })
  },

  // [TYPES.IMAGE_SUGGESTION_SET_PARAMS](_state, obj) {
  //   _state.imageSuggestions = obj
  // },

  // [TYPES.SET_MATCHING_PRODUCTS](_state, obj) {
  //   _state.matchingProducts = obj
  // },
  [TYPES.SET_PRODUCT_CATEGORIES](_state, categories) {
    _state.categories = categories
  },

  [TYPES.REMOVE_PRODUCT_CATEGORY](_state, id) {
    _state.categories.splice(_state.categories.findIndex((c) => c.id == id), 1)

  },

  [TYPES.AMAZON_ROOT_CATEGORY](_state, { categories, id }) {
    _state.categoryState.rootCategories.amazon[id] = categories
  },

  [TYPES.EBAY_ROOT_CATEGORY](_state, { categories, id }) {

    _state.categoryState.rootCategories.ebay[id] = categories
  },

  [TYPES.EBAY_CHILD_CATEGORY](_state, { categories, id }) {
    _state.categoryState.rootCategories.ebay[id].childCategories = categories
  },

  [TYPES.AMAZON_CHILD_CATEGORY](_state, { categories, id }) {
    _state.categoryState.rootCategories.amazon[id].childCategories = categories
  },

  [TYPES.SET_JOB_ID](_state, Id) {
    _state.jobId = Id
  },
  [TYPES.TOGGLE_TRIGGER_STATUS](_state) {
    _state.triggerstatus = !_state.triggerstatus
  },
  [TYPES.SET_ELOADING_CLOSE_STATUS](_state, status) {
    _state.eLoadingCloseStatus = status
  }
}

export interface Product {
  id: number
  [key: string]: any,
  extra_attributes: {
    [key: string]: any,
  }
}

export interface ProductUpdateRequest {
  id: number,
  payload: {
    product: Product
  }
}

const actions = {

  saveCatlogSettings({ dispatch }, obj) {
    return services.saveCatlogSettings(obj).then((res) => {
      return res
    })
  },

  matchOnAmazon({ dispatch }, obj) {
    return services.matchOnAmazon(obj)
  },

  matchOnEbay({ dispatch }, obj) {
    return services.matchOnEbay(obj)
  },

  archiveProduct({ dispatch }, obj) {
    return services.archiveProduct(obj)
  },
  deleteProduct({ dispatch }, id) {
    return services.deleteProduct(id)
  },
  // getProductImages({ dispatch }, id) {
  //   return services.getProductImages(id).then((response) => {
  //     dispatch('productsSetImages', {
  //       [id]: response.data.images
  //     })
  //     dispatch('setFetching', { fetching: false })
  //   })
  // },

  // deleteProductImage({ dispatch }, id) {
  //   return services.deleteProductImage(id)
  // },

  // getImageSuggestions({ dispatch }, obj) {
  //   return services.getImageSuggestions(obj).then((response) => {
  //     dispatch('imageSuggestionSetParams', {
  //       searchValue: obj.searchValue,
  //       searchBy: obj.searchBy,
  //       src: obj.src,
  //       images: response.data.images
  //     })
  //   })
  // },
  replaceInProducts({ dispatch }, obj) {
    return services.replaceInProducts(obj).then((res) => {
      return res
    })
  },
  getProductFields() {
    return services.getProductFields().then((res) => {
      return res
    })
  },

  getImportRecords({ dispatch }, obj) {
    return services.getImportRecords(obj).then((response) => {
      dispatch('productsImportSetData', {
        imports: response.data.background_jobs,
        pagination: {
          count: obj.limit,
          current_page: 1,
          per_page: obj.limit,
          total: response.data.total,
          //total_pages: obj.totalPages(response.data.total),
          //last_page: parseInt(obj.totalPages(response.data.total))
        }
      })
      // dispatch('setFetching', { fetching: false })
    })
  },

  importProducts({ dispatch }, obj) {
    return services.importProducts(obj).then((response) => {
      return {
        success: response.data.success,
        message: response.data.message
      }
    })
  },

  deleteImportRecord({ dispatch }, id) {
    return services.deleteImportRecord(id).then((response) => {
      dispatch('productsImportRemoveData', id)
      return response
    })
  },

  editImportRecord({ dispatch }, obj) {
    return services.editImportRecord(obj)
  },

  // syncImages({ dispatch }, id) {
  //   return services.syncImage(id).then((res) => {
  //     dispatch('setMessage', {
  //       type: 'success',
  //       message: 'Images have been processed for syncing'
  //     })
  //     return res;
  //   })
  // },
  // getMatchingProducts({ dispatch }, ids) {
  //   return services.getMatchingProducts(ids).then((response) => {
  //     dispatch('setFetching', { fetching: false })
  //     return response;
  //   })
  // },
  //
  // getEbayMatchedProducts({ dispatch }, ids) {
  //   return services.getEbayMatchingProducts(ids).then((response) => {
  //     dispatch('setFetching', { fetching: false })
  //     return response;
  //   })
  // },

  getEbayChildCategories({ commit }, id) {
    return services.getEbayChildCategories(id).then((res) => {
      return res
    })
  },

  getAmazonChildCategories({ commit }, id) {
    return services.getAmazonChildCategories(id).then((res) => {
      return res
    })
  },

  fetchImportcatalogSettings({ dispatch }, id) {
    return services.getImportcatalogSettings(id).then((res) => {
      return res
    })
  },

  /**
   * Queries the categories over API and sets in the store.
   */
  getCategories({ dispatch }, id) {
    return services.getCategories(id).then((response) => {
      dispatch('setCategories', response.data.categories)
      return response
    })
  },
  getAmazonRootCategories({ commit }, id) {
    return services.getAmazonRootCategories(id).then((res) => {
      commit(TYPES.AMAZON_ROOT_CATEGORY, { categories: res.data.categories, id })
      return res
    })
  },
  toggleTriggerStatus({ commit }) {
    commit(TYPES.TOGGLE_TRIGGER_STATUS)
  },

  getEbayRootCategories({ commit }, id) {
    return services.getEbayRootCategories(id).then((res) => {
      commit(TYPES.EBAY_ROOT_CATEGORY, { categories: res.data.categories, id })
      return res
    })
  },

  getEbayCodes() {
    return services.getEbaySiteCodes().then((res) => {
      return res
    })
  },

  productsBulkUpdate({ dispatch }, obj) {
    return services.productsBulkUpdate(obj).then((res) => {
      dispatch('setMessage', {
        type: 'success',
        message: 'Product(s) updated successfully.'
      })
      return res
    })
  },

  // productsSetImages({ commit }, obj) {
  //   commit(TYPES.PRODUCTS_SET_IMAGES, obj)
  // },

  // productsAddImage({ commit }, obj) {
  //   commit(TYPES.PRODUCTS_ADD_IMAGE, obj)
  // },

  productsImportSetData({ commit }, obj) {
    commit(TYPES.PRODUCTS_IMPORT_SET_DATA, obj)
  },

  productsImportRemoveData({ commit }, id) {
    commit(TYPES.PRODUCTS_IMPORT_REMOVE_DATA, id)
  },

  setCategories({ commit }, categories) {
    commit(TYPES.SET_PRODUCT_CATEGORIES, categories)
  },

  removeCategorySuccess({ commit }, id) {
    commit(TYPES.REMOVE_PRODUCT_CATEGORY, id)
  },

  setJobID({commit},payload){
    commit(TYPES.SET_JOB_ID, payload);
  },
  setELodingCloseStatus({ dispatch }, status) {
    console.log(status, 'test')
    dispatch('eLodingCloseStatus', status)
  },
  eLodingCloseStatus({ commit }, payload) {
    commit(TYPES.SET_ELOADING_CLOSE_STATUS, payload)
  }
}

const getters = {
  getJobID: function (_state) {
    return _state.jobId
  }
}


export default {
  module: {

    state,
    mutations,
    actions,
    getters
  }
}
