import * as TYPES from '../../store/types'
import * as services from './services'

const actions = {
    getCaFolders(){
        return services.getCaFolders().then((res)=>{
            return res
        })
    },
    updateCaFtpSftpFolders({dispatch},obj){
        return services.updateCaFtpSftpFolders(obj).then((res)=>{
            return res
        })
    },
    createCaFtpSftpCreadentials({dispatch},obj){
        return services.createCaFtpSftpCreadentials(obj).then((res)=>{
            return res
        })
    },
    getAttribute() {
        return services.getAttributes().then((res) => {
            return res;
        })
    },
    getConfigurations({ dispatch }, id) {
        return services.getConfigurations(id).then((res) => {
            return res
        });
    },
    getFTPCredentialStatus({dispatch}, obj) {
        return services.getFTPCredentialStatus().then((res)=>{
            return res;
        });
    },
    saveConfigurations({ dispatch }, obj) {
        return services.configurations(obj).then((res) => {
            return res;
        });
    },
    saveRequestAccess({ dispatch }, obj) {
        return services.saveRequestAccess(obj).then((res) => {
            return res;
        });
    },
    getImageAbb({ dispatch }, id) {
        return services.getImageAbbrivation(id);
    },
    getCaStatus({ dispatch }, obj) {
        return services.getCaStatus(obj).then((res) => {
            return res;
        })
    },
    saveImageAbbr({ dispatch }, obj) {
        return services.saveImgAbbr(obj).then((res) => {
            return res;
        })
    },
    saveEbayToken({ dispatch }, obj){
          return services.saveEbayToken(obj).then((res) => {
            return res;
        })
    },
    availableIntervals({ dispatch }, obj) {
        return services.getAvailAbleIntervals(obj).then((res) => {
            return res;
        })
    },
    updateInterval({ dispatch }, obj) {
        return services.updateInterval(obj);
    },
}

export default { module: {
    actions
} }