import * as c from './constants';
export default {
    [c.EDIT_VARIATION]: (state, variation) => {
        state.editVariation = variation;
    },

    [c.RESET_VARIATION]: (state) => {
        state.editVariation = null;
    },
    
    [c.VARIATION_REMOVED]: (state, payload) => {
        state.variationRemoved = payload;
    }
}