import { http } from '@/utils/plugins/http'

export const getCompanies = () => http.get(`/companies`);

export const getCompaniesList = (obj) => http.get(`/companies?page_no=${obj.page_no}&per_page_count=${obj.per_page_count}`)

export const getFullCompaniesList = () => http.get(`/companies?per_page_count=100`)

export const changeCompany = (obj) => http.post('users/change_company?company_id=' + obj.company_id)

export const getCurrentCompany = () => http.get('/users/current_company')

export const saveCompany = obj => http.post('/companies', obj)

export const updateCompany = ({ id, company }) => http.put(`/companies/${id}`, company)

export const deleteCompany = (id) => http.delete(`companies/${id}`)

export const getCompany = (id) => http.get(`companies/${id}`)
export const getCompanyUsers = (id) => http.get(`companies/${id}/users`)

export const getCompanyUserRoles = (company_id) => http.get(`companies/fetch_roles?company_id=${company_id}`)

export const saveLogo = ({ id, data }) => http.put(`companies/${id}/add_logo`, data)

export const setCompanyUserRole = ({ id, payload }) => http.put(`companies/${id}/users`, payload)

export const removeCompanyUser = ({ id, user_id }) => http.delete(`companies/${id}/users?user_id=${user_id}`)

export const inviteCompanyUserEmail = (obj) => http.post(`company_users/invite_user?company_id=${obj.user.company_id}`, obj)

export const addNotification = (obj) => http.post(`companies/${obj.company_id}/notifications`, obj.notifications)