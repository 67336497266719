import { defineAsyncComponent } from 'vue'
declare let require: any

const Main = defineAsyncComponent(() => import('./main.vue'))
const accepted = defineAsyncComponent(() => import('./ebay-authorization-accepted.vue'))
const rejected = defineAsyncComponent(() => import('./ebay-authorization-rejected.vue'))

export default [
  {
    name: 'configurations.index',
    path: '/settings/config',
    component: Main,

  },
  {
    name: 'ebay.config.approve',
    path: '/ebay/accepted',
    component: accepted,

  },
  {
    name: 'ebay.config.reject',
    path: '/ebay/rejected',
    component: rejected,

  }
]
