import {http} from '@/utils/plugins/http';
import {errorNotify, shouldRedirectToLogin, authToken } from '@/utils/helpers'

const actionScope = 'loader';
export function setupInterceptors({ dispatch }, router) {
  let redirectingToLogin = false;
  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending++;
      dispatch(`${actionScope}/show`);
    },
    done: () => {
      requestsPending--;
      if (requestsPending <= 0) {
        dispatch(`${actionScope}/hide`);
      }
    }
  };

  http.interceptors.request.use((config) => {
    config.headers.Authorization = authToken();
    return config;
  }, (error) => Promise.reject(error));

  http.interceptors.response.use(response => {
    req.done();
    return response
  },
  (error) => {
    if(shouldRedirectToLogin(error, router)) {
      dispatch('signOut')
      router.push('/login')
      redirectingToLogin = true
      return Promise.reject(error)
    }
    else {
      if(!redirectingToLogin) errorNotify(error)
    }
    return Promise.reject(error)
  }
  )
}

