
<template>
  <div :class="`${!drawer ? 'sidebar-menu-open' : 'sidebar-menu-close'}`">
    <div v-for="(item, index) in menus" :key="index">
      <el-sub-menu :index="item.id" v-if="item.children" :teleported="true" :open="drawer">
        <template #title>
          <el-unicon :name="item.icon" fill="currentColor" height="17px" width="17px" />
          <span :data-cy="`${toKebabCase(item.text)}-btn`">{{ item.text }}</span>
        </template>
        <el-menu-item-group :class="`${!drawer ? 'open-menu has-child' : ''}`">
            <el-menu-item :index="child.id" :data-cy="`${toKebabCase(child.text)}-btn`" v-for="(child, i) in item.children"
            :content="child.text" :key="i">
              <el-unicon :name="child.icon" fill="currentColor" height="14px" width="14px" />
              <template #title>
                <span>{{ child.text }}</span>
              </template>
            </el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
        <el-menu-item :index="item.id" :data-cy="`${toKebabCase(item.text)}-btn`" v-else>
          <el-unicon :name="item.icon" fill="currentColor" height="17px" width="17px" />
          <template v-if="!item.children && item.to">
            <span>{{ item.text }}</span>
          </template>
        </el-menu-item>
    </div>
  </div>
</template>
<script>
import { toKebabCase } from '@/utils/helpers'

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    drawer: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toKebabCase
  }
}
</script>
<style scoped>
.sidebar-menu-close {
  margin-left: 3px;
}

.sidebar-menu-close :deep().el-sub-menu .el-menu-item-group>ul {
  margin-left: 0;
}

.sidebar-menu-close :deep().el-menu-item span,
.sidebar-menu-close :deep().el-sub-menu__title span,
.sidebar-menu-close :deep().el-sub-menu__title .el-icon-arrow-down,
.sidebar-menu-close :deep().el-menu-item-group__title span {
  display: none;
}

.sidebar-menu-close :deep().is-opened .el-menu-item-group::before {
  content: " ";
  border: 0.5px solid #090C1C;
  display: block;
  text-align: center;
  margin: auto;
  width: 32px;
}

.sidebar-menu-close :deep().el-sub-menu.is-opened {
  background: #0F122E;
  border-radius: 10px 0 0 0;
}

.sidebar-menu-close :deep().el-menu-item {
  margin: 4px 0;
}

.sidebar-menu-close :deep().el-menu-item,
:deep().el-sub-menu__title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  left: 10px;
  color: #B3B8ED;
}

.sidebar-menu-close :deep().el-sub-menu.is-opened .el-menu-item-group {
  padding-bottom: 6px;
}
</style>
<style>
.el-tooltip__popper.is-dark.show-tooltip {
  background: #0F122E;
  box-shadow: 0px 0px 15px rgba(9, 12, 28, 0.1);
  border-radius: 5px;
  margin-left: 5px;
}

.el-tooltip__popper[x-placement^=right].show-tooltip .popper__arrow::after {
  border-right-color: #0F122E;
}

.el-tooltip__popper.is-dark.hide-tooltip,
.el-tooltip__popper[x-placement^=right].hide-tooltip .popper__arrow::after {
  display: none;
}

.el-tooltip__popper.is-dark.show-tooltip.sub-menu {
  margin-left: -115px;
}
</style>