import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { authRoutes, adminRoutes } from '@/app/all-routes'
import NotFound from '@/components/not-found.vue'
import MainLayout from '@/layouts/master/MainLayout.vue'
import AuthMiddleware from '@/utils/middleware/auth.middleware.js'
import SetHeaderMetaMiddleware from '@/utils/middleware/headerMeta.middleware.js'
import middlewarePipeline from './middlewarePipeline'

const adminRoutesArray = adminRoutes.map(function (obj: any) {
  if (!obj.meta) {
    obj.meta = {}
  }
  obj.meta.middleware = [AuthMiddleware, SetHeaderMetaMiddleware]
  return obj
})

const routes = [
  ...authRoutes,
  {
    path: '/',
    component: MainLayout,
    children: adminRoutesArray,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
]

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
})


router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  }

  return next()
})

export default router
