
import MappingTemplateInfoBox from './../components/MappingTemplateInfoBox.vue'
import CategoryMappingTemplate from '../components/CategoryMappingTemplate.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    MappingTemplateInfoBox,
    CategoryMappingTemplate,
  },
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onAddTemplate', 'onEditTemplate', 'onDeleteTemplate'],
  computed: {
    ...mapActions({
      getAllTemplates: 'getTemplates',
    }),
  },
  methods: {
    addTemplate() {
      this.$emit('onAddTemplate')
    },
  },
}
