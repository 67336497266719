
import { mapGetters } from 'vuex';
import {MenuItems} from './menu-items'
import SidebarMenu from './SidebarMenu.vue'
import SidebarToggler from './SidebarToggler.vue'
import SidebarProfile from './SidebarProfile.vue'

export default {
  name: 'SidebarMain',
  components:{
    SidebarMenu,
    SidebarToggler,
    SidebarProfile
  },
  data() {
    return {
      items: [],
      isToggleProfile: false,
      activeMenu: '',
      openSubMenus:[],
      drawer:false
    }
  },
  computed : {
    ...mapGetters (['user'])
  },
  watch: {
    '$route'(to, from) {
      if(this.$route.path === '/user/profile'){
        this.activeMenu = '';
        if(!this.drawer)
          this.openSubMenus = [];
      }
      else
        this.handleRoutingActiveMenu();
    }
  },
  mounted(){
    this.initMenu();
    this.handleRoutingActiveMenu();
  },
  methods: {
    handleRoutingActiveMenu(){
      const path = this.$route.path;
      const items = this.items;
      items.forEach((item,index)=>{
        if(Array.isArray(item.children)){
          item.children.forEach((child,i)=>{
            if(child.to === path){
              if(!this.drawer)
                this.openSubMenus = [item.id]
              this.activeMenu = child.id;
            }
          })
        }
        else if(item.to === path){
          this.activeMenu = item.id;
          if(!this.drawer)
            this.openSubMenus = [];
        }
      })
    },
    openSubMenusInClose(id){
      this.openSubMenus = [id]
    },
    scrollUpOpenMenus(id){
      if(!this.drawer)
        this.openSubMenus=[id];
      else
        this.openSubMenusInClose(id)
    },
    handleMenuOpen(key, keyPath){
      let index, childIndex, item;
      index = keyPath[0]
      childIndex = !keyPath[1]?null:(keyPath[1].split('-'))[1]
      const menus = this.items;
      if(childIndex){
        item = menus[index].children[childIndex]
      }
      else{
        if(!this.drawer)
          this.openSubMenus = [];
        item = menus[index]
      }
      this.activeMenu = key;
      this.$router.push(item.to)
    },
    initMenu(){
      let items = MenuItems;
      items.forEach((item:any,index)=>{
        item.id = index.toString();
        if(item.children){
          item.children.forEach((child,i)=>{
            child.id = `${index.toString()}-${i.toString()}`
            /*child['showMenu'] = false;
                if(child.metas.roles.indexOf(this.user['role']) !== -1){
                  child['showMenu'] = true;
                }*/
          })
        }
        /*if(item.metas){
              item['showMenu'] = false;
              if(item.metas.roles.indexOf(this.user['role']) !== -1)
                item['showMenu'] = true;
            }*/
      })
      this.items = items;
    },
    drawerToggled(drawer){
      this.drawer = drawer
      this.$emit('drawerToggled',drawer)

    },
    onLogout(){
      this.$emit('logout')
    }
  }
};
