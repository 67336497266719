import * as services from '../service/services'

const state = {
}

const mutations = {

}

const actions = {
  getCatalogSources({ dispatch }) {
    return services.getCatalogSources()
  },
  changeProductCategory({ dispatch }, obj) {
    return services.changeProductCategory(obj).then((res) => {
      dispatch('setMessage', {
        type: 'Success',
        message: 'Category Changed Successfully'
      })
      return res
    })
  },
  updateProductAttributes({ dispatch }, obj) {
    const productId = obj.id
    const extra_attributes = obj.extra_attributes
    return services.updateProductAttributes(productId, extra_attributes).then((response) => {
      dispatch('setMessage', {
        type: 'Success',
        message: 'Attributes Updated'
      })
      return response
    })
  },

  addProductCategory({ dispatch }, obj) {
    return services.addProductCategory(obj).then((response) => {
      dispatch('setMessage', {
        type: 'Success',
        message: 'Category has been added to the product'
      })
      return response
    })
  },
  removeProductCategory({ dispatch }, id) {
    return services.removeProductCategory(id).then((response) => {
      dispatch('removeCategorySuccess', id)
      dispatch('setMessage', {
        type: 'Success',
        message: 'Category has been removed from the product'
      })
    })
  },
  fetchProductCategories({ dispatch }, product_id) {
    return services.fetchProductCategories(product_id).then((response) => {
      dispatch('setCategories', response.data.product_categories)
      return response
    })
  },
  async fetchCategoryDetails({ dispatch }, data = { sourceId: -1, categoryId: -1, group: '', product_id: '' }) {
    let res
    switch (data.sourceId) {
    case 1:
      if (data.group)
        res = await services.getAmazonCategoryGroupAttrs(data.categoryId, data.group, data.product_id)
      else
        res = await services.getAmazonRefinements(data.categoryId)
      break
    case 3:
      res = await services.getEbaySpecifics(data.categoryId, data.product_id)
      break
    default:
      console.log(`cannot fetch category details as the catalog source id ${data.sourceId} does not match any of the supported sources`)
    }
    return res
  },
  //To do remove this
  // async fetchCategoryGroups({ dispatch }, data = { sourceId: -1, categoryId: -1}) {
  //   let res;
  //   switch (data.sourceId) {
  //     case 1:
  //       res = await services.getAmazonCategoryGroups(data.categoryId);
  //       break;
  //     default:
  //       return []

  //   }
  //   return res;
  // }
}


export default {
  module: {
    state,
    mutations,
    actions
  }
}
