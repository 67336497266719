import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"position":"relative"} }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_breadcrumb, {
      separator: "/",
      class: "breadcrumb-vue",
      style: {"box-shadow":"0 2px 4px -1px rgba(0, 0, 0, 0), 0 4px 5px 0 rgba(0, 0, 0, 0.02), 0 1px 10px 0 rgba(0, 0, 0, 0.03)"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links.navs, (link, index) => {
          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: link.to
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(link.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.links.current), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "link-right-breadcrumb",
      to: "/user/profile",
      id: "components-breadcrum-my-profile"
    }, {
      default: _withCtx(() => [
        _createTextVNode("My Profile")
      ]),
      _: 1
    })
  ]))
}