import { createApp } from 'vue';
import ElementUI from 'element-plus';
import { createApolloProvider } from '@vue/apollo-option';
import vuescroll from 'vuescroll';
import 'element-plus/theme-chalk/index.css';
// import locale from "element-plus/lib/locale/lang/en";
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import vueDebounce from 'vue-debounce'
import 'regenerator-runtime';
import Unicon from './components/Unicon.vue'
import '@iconscout/unicons/css/line.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { apolloClient } from './utils/vue-apollo'
// import "./utils/directive/jquery-plugins"; // tod check its usage
import { filters } from './utils/filters/filters';
import store from './store/index';
import router from './router/index';
import eventbus from './utils/plugins/eventbus';
import http from '@/utils/plugins/http';
import { setupInterceptors } from './utils/httpInterceptors';
import './assets/css/main.scss';
import App from '@/App.vue'
import { registerComponents } from '@/components/globals/UI/index'
import CustomDirectives from '@/utils/plugins/custom-directives';
import SharedComponents from '@/utils/plugins/shared-components';

setupInterceptors(store, router);//http
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})
const app = createApp(App);
//register all elementplus icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$filters = filters;
app.use(apolloProvider);

// Sentry.init({
//   dsn: 'https://ac446b3841b24f2e942f904e8dfcc320@sentry.io/1455311',
//   integrations: [
//     new Integrations.Vue({
//       Vue,
//       attachProps: true,
//       logErrors: true
//     })
//   ],
//   environment: ENV
// });

// @ts-ignore: Unreachable code error
app.use(vuescroll,{
  ops:{
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: true,
      background: '#4D4D4D',
      minSize: 0.1,
      size: '4px',
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true,
      speed: 300,
    }
  }
})
app.use(CustomDirectives);
app.use(SharedComponents);
registerComponents(app);
app.use(vueDebounce);
app.use(eventbus);
app.use(ElementUI);
app.use(http);
app.use(router);
app.use(store);
app.component('ElUnicon', Unicon);
app.mount('#app');
export default app;
