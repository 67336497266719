<template>
  <i :class="`uil uil-${name}`" :style="{ color: fill, paddingRight: right }"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: () => '',
    },
    fill: {
      type: String,
      default: () => 'currentcolor',
    },
    width: {
      type: String,
      default: () => '14px',
    },
    right: {
      type: String,
      default: () => '0px',
    },
    weight: {
      type: String,
      default: () => 'normal',
    },
  }
}
</script>

<style scoped>
.uil:before {
  font-size: v-bind('width');
  vertical-align: middle;
  font-weight: v-bind('weight');
}
.uil {
  padding-right: v-bind('right');
}
</style>



