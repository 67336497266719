import * as TYPES from '@/store/types'
import * as services from '../service/productDetails.service'

const state = {
  images:[]
};

const mutations = {
  [TYPES.PRODUCT_SET_IMAGES](_state, images) {
    _state.images = images
  },
  [TYPES.PRODUCT_REMOVE_IMAGES](_state, ids) {
    const new_images_list = [];
    if(_state.images){
      _state.images.forEach((img)=>{
        if(ids.indexOf(img.id) < 0)
          new_images_list.push(img)
      })
    }

    _state.images = new_images_list
  },

  [TYPES.PRODUCTS_ADD_IMAGE](_state, uploadedImages) {
    _state.images = _state.images.concat(uploadedImages)
  }
}


const actions = {
  getProduct(_, id) {
    return services.getProduct(id).then((response) => {
      return response.data.product
    })
  },
  saveProduct({ dispatch }, obj) {
    return services.saveProduct(obj).then((response) => {
      dispatch('setMessage', {
        type: 'Success',
        message: 'Product added successfully.'
      })
      // dispatch('setFetching', { fetching: false })
    })
  },
  updateProduct({ dispatch }, obj) {
    return services.updateProduct(obj).then((response) => {
      dispatch('setMessage', {
        type: 'Success',
        message: 'Product updated successfully.'
      })
      dispatch('setFetching', { fetching: false })
      return true
    }).catch((error) => {
      dispatch('setMessage', {
        type: 'Error',
        message: `Failed to update product: ${error}`
      })
      return false
    })
  },
}

const getters = {
  getCurrentProductFromStore(rootState) {
    return rootState.currentProduct
  }
}


export default { module: {
  state,
  mutations,
  actions,
  getters
} };
