import { http } from '@/utils/plugins/http'
import { apolloClient as gclient } from '@/utils/vue-apollo'
import { gql } from 'graphql-tag'

export const getEcompimFields = () => http.get('/fields/internal')
export const getExternalSourceFields = (type) => http.get(`/fields/${type}`)
export const getEtraAttributesFields = (company_id) =>
  http.get(`/fields/channel_advisor_extra_attributes/${company_id}`)

export const getTemplates = (category) =>
  http.get(`/templates?category=${category}`)
export const getEditTemplate = (id) => http.get(`/templates/${id}`)
export const updateTemplate = ({ id, params }) =>
  http.put(`/templates/${id}`, params)

import { getInboundMappingTemplate, saveMappingTemplate } from '../graphql/queries'

export const getMappingTemplateAttributes = (
  source_marketplace_id,
  category_id
) => {
  return gclient.query({
    query: getInboundMappingTemplate,
    variables: {
      marketplace_id: source_marketplace_id,
      category_id: category_id,
    },
    fetchPolicy: 'no-cache',
  })
}

export const saveTemplate = (
  category_id,
  source_marketplace_id,
  target_id,
  mapping_fields
) => {
  return gclient.mutate({
    mutation: saveMappingTemplate,
    variables: {
      marketplace_id: source_marketplace_id,
      category_id: category_id,
      target_id: target_id,
      mapping_fields: mapping_fields,
    },
  })
}
export const deleteTemplate = (category_id) => {
  return gclient.mutate({
    mutation: gql`
      mutation ($category_id: Int!) {
        remove_mapping_template(category_id: $category_id)
      }
    `,
    variables: {
      category_id: category_id,
    },
  })
}

export const saveOutboundTemplate = (params) => http.post(`templates`, params)
export const deleteOutboundTemplate = (id) => http.delete(`templates/${id}`)

