export default {
    loading: false,
    response: {},
    types: {data: {history_types: []}},
    error: '',
    history_type: "",
    pagination: {
        per_page_count: 10,
        page_no: 1,
    }

}