
import { mapActions } from 'vuex'
import { getCatalogSourcesKeyedById } from '@/utils/common/sources'
export default {
  inject: {
    catalogSourceSelectionDisable: { default: false },
    marketplaceSelectionDisable: { default: false },
  },
  props: {
    defaultCatalogSourceId: {
      type: Number,
    },
    defaultCatalogSourceMarketplaceId: {
      type: Number,
    },
  },
  data() {
    return {
      marketPlaces: [],
      categorySearchTerm: '',
      searchIcon: 'search',
      selectedCatalogSource: '',
      selectedMarketPlace: '',
      catalog_sources: {},
    }
  },
  async mounted() {
    let catalog_sources = await getCatalogSourcesKeyedById()
    this.catalog_sources = catalog_sources

    //assing default configured catalog source or first catalog source in catalog sources list
    this.selectedCatalogSource =
      this.defaultCatalogSourceId ?? +Object.keys(this.catalog_sources)[0]

    this.onCatalogSourceChange()
  },

  methods: {
    onCatalogSourceChange() {
      if (!this.selectedCatalogSource) return
      let marketplaces =
        this.catalog_sources[this.selectedCatalogSource].marketplaces
      this.marketPlaces = marketplaces
      this.selectedMarketPlace = this.defaultCatalogSourceMarketplaceId ?? marketplaces?.[0]?.id
      this.onRegionChange()
    },
    onRegionChange() {
      this.$emit(
        'marketPlaceChange',
        this.selectedCatalogSource,
        this.selectedMarketPlace
      )
    },
    searchTermDebounce() {
      if (this.categorySearchTerm) this.searchIcon = 'close'
      else this.searchIcon = 'search'
      this.$emit('searchTermDebounce', this.categorySearchTerm)
    },
    searchTermChange() {
      this.$emit('searchTermChange', this.categorySearchTerm)
    },
    clearInputField() {
      this.categorySearchTerm = ''
      this.$emit('searchTermDebounce', '')
    },
    ...mapActions(['fetchCatalogSources']),
  },
}
