import * as c from './constants';

export default {
    [c.GENERATE_SIZE_TABLE_IMAGE]: (state, payload) => {
        state.generateSizeTableImage = payload;
    },

    [c.ADD_SIZE_TABLE_VALUES]: (state, payload) => {
        state.sizeTableImage = payload;
    },

    [c.SIZE_TABLE_IMAGE]: (state, payload) => {
        state.sizeTableImage = payload;
    },

    // sets the keyword typed into the
    [c.SET_KEYWORD]: (state, payload) => (
        state.keywords = payload
    ),

    // sets search results returned from the query 
    [c.SET_RESULTS]: (state, payload) => (
        state.keywordResults = payload
    ),
    
    [c.SET_PRODUCTS]: (state, payload) => {
        state.selectedProducts = payload
    },

    
    [c.LOADING]: (state) => {
        state.loading = !state.loading
    },
    
    [c.PREVIEW_LOADING]: (state) => {
        state.prevewLoading = !state.prevewLoading
    },

    
    [c.SAVED]: (state, payload) => {
        state.saved = payload
    },

    [c.PREVIEW]: (state, payload) => {
        state.preview = payload
    },

    [c.GENERATED]: (state, payload) => (
        state.generated = payload
    ),
    
}