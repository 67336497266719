<template>
  <el-row type="flex" justify="start" align="middle" class="navbar-wrapper">
    <div>
      <h2 class="main-header-title">{{ headerTitle }}</h2>
    </div>
    <el-row type="flex" style="margin-left:auto" align="middle">
      <el-dropdown v-model="menu" trigger="click" class="el-dropdown" >
        <span class="el-dropdown-link">
          <div class="navbar-notification-dropdown" style="margin-right:20px">
            <el-icon size=20 class="el-icon--right">
              <bell color="black"/>
            </el-icon>
          </div>
        </span>
        <template #dropdown>
          <el-dropdown-menu style="padding:0">
            <el-dropdown-item class="dropdown-header" style="text-align:center">
                <h5 style="margin: 0;" >Notifications</h5>
            </el-dropdown-item>
              <hr style="margin: 0;">
            <el-dropdown-item style="padding:5px" class="not-dropdown-item">
              <notifications-list />
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown v-model="menuNotifications" trigger="click" class="settings el-dropdown">
        <span class="el-dropdown-link change-company dropdown-title">
          <span style="color: #000">{{limitTo()}}</span>
          <el-icon class="el-icon--right">
            <caret-bottom color="black"/>
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu  style="padding:0">
            <el-dropdown-item class="dropdown-header">
                <p style="margin: 0;padding:5px 0">Your Companies</p>
            </el-dropdown-item>
            <hr style="margin: 0;">
            <el-dropdown-item :context="'companies-holder'" style="padding:0">
              <div
                v-loading="loadingCompanies"
                :size="50"
                color="primary"
                indeterminate
              ></div>
              <companies-list :current-company="currentCompany" @companyChanged="handleCompanyChange"/>
            </el-dropdown-item>
            <hr style="margin: 0;">
            <el-dropdown-item class="dropdown-header">
                <p style="margin: 0;padding:5px 0">Current Company:<b>{{currentCompanyName}}</b></p>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown v-model="menu" trigger="click" class="settings el-dropdown">
        <span class="el-dropdown-link dropdown-title">
          <span style="color:#000;">{{ username }}</span>
          <el-icon class="el-icon--right">
            <caret-bottom color="black"/>
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="profile-dropdown">
            <el-dropdown-item class="profile-dropdown-item">
              <router-link
                  id="navbar-link-user-profile"
                  style="color:#000"
                  to="/user/profile"
                >
                  Profile
                </router-link>
            </el-dropdown-item>
            <el-dropdown-item class="profile-dropdown-item">
              <a
                id="navbar-link-user-logout"
                style="color:#000"
                href="javascript:;"
                @click.prevent="logOut"
              >
                Logout
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-row>
  </el-row>
</template>

<script>
import { CaretBottom, Bell } from '@element-plus/icons-vue'
import CompaniesList from './CompaniesList.vue'
import NotificationsList from './NotificationsList.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'MainNavbar',
  components: {
    'companies-list': CompaniesList,
    'notifications-list': NotificationsList,
    CaretBottom,
    Bell
  },
  data() {
    return {
      menu: false,
      menuOpacity: 0,
      menuNotifications: false,
      loadingCompanies: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'headerTitle']),
    ...mapState({
      currentCompany(state) {
        return state.Companies.currentCompany.company
          ? state.Companies.currentCompany.company
          : {}
      },
    }),
    username() {
      return this.user ? this.user.name : ''
    },
    currentCompanyName() {
      if (
        this.currentCompany &&
        Object.prototype.hasOwnProperty.call(this.currentCompany, 'name')
      ) {
        return this.currentCompany.name
      }
      return 'loading...'
    },
    currentCompanyId() {
      if (
        this.currentCompany &&
        Object.prototype.hasOwnProperty.call(this.currentCompany, 'id')
      ) {
        return this.currentCompany.id
      }
      return ''
    },
  },
  methods: {
    ...mapActions([
      'setUser',
      'changeCompany',
      'signOut',
      'companiesSetCurrentCompanyByObject',
    ]),
    logOut() {
      this.signOut().then(() => {
        this.$router.push({ name: 'login.index' })
      })
    },
    async handleCompanyChange(company_id) {
      if (this.currentCompanyId != company_id) {
        const payload = {
          company_id,
        }
        await this.changeCompany(payload)
          .then((response) => {
            if (response.data) {
              const {
                data: {
                  user: { company },
                },
              } = response
              let user = this.user
              if (!user) {
                user = response.data.user
              }
              user.activeCompanyId = company.id
              user.activeCompanyName = company.name
              this.companiesSetCurrentCompanyByObject({ company })
              this.setUser(user)
              this.$message({
                message: 'Active company has been changed.',
                type: 'success',
              })
              if (this.$route.path !== '/')
                this.$router.replace({ name: 'dashboard.index' })
            } else {
              this.$message({
                message: 'Some error occured. Please contact admin.',
                type: 'Error',
              })
            }
          })
          .catch((e) => {
            console.log(e.message)
          })
      }
    },
    limitTo() {
      return this.currentCompanyName
        ? this.currentCompanyName.length > 30
          ? this.currentCompanyName.substring(0, 30) + '....'
          : this.currentCompanyName
        : ''
    },
  },
}
</script>

<style scoped>
.main-header-title {
  font-size: 18px;
  color: #505370;
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.navbar-wrapper {
  height: 100%;
  padding-right: 20px;
}
.dropdown-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-dropdown {
  margin-right: 10px;
}
.profile-dropdown {
  padding: 0;
}
.profile-dropdown-item {
  padding: 0;
}
.profile-dropdown-item a {
  padding: 5px 40px;
}

.not-dropdown-item:hover {
  background: none;
  cursor: default;
}

.main-brand {
  cursor: pointer;
  position: relative;
  top: -10px;
}

.companies-list {
  overflow-y: hidden;
}
.el-dropdown-menu {
  overflow-y: auto;
  overflow-x: hidden;
}
.application .theme--dark.v-toolbar,
.theme--dark .v-toolbar {
  background-color: #fff;
  color: #000;
  z-index: 2;
}
.v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
@media (max-width: 768px) {
  .nav-open {
    padding-left: 80px !important;
    transition: all 0.8s;
  }
  :deep(li.el-dropdown-menu__item[context="companies-holder"]) {
    padding:0;
  }
}
</style>
