import * as TYPES from '@/store/types'
import * as services from '../service/productImages.services'

const state = {
   // images: {},
  imageSuggestions: {
    searchValue: '',
    searchBy: '',
    src: '',
    images: null
  }
};

const mutations = {
  [TYPES.IMAGE_SUGGESTION_SET_PARAMS](_state, obj) {
    _state.imageSuggestions = obj
  }
}

const actions = {
  getProductImages({ dispatch }, id) {
    return services.getProductImages(id).then((response) => {
      dispatch('productsSetImages', response.data.images);
    });
  },
  removeProductImages({ commit }, ids) {
    commit(TYPES.PRODUCT_REMOVE_IMAGES, ids)
  },
  deleteProductImage({ dispatch }, id) {
    return services.deleteProductImage(id)
  },
  deleteMultipleProductImages({ dispatch }, payload) {
    return services.deleteMultipleProductImages(payload)
  },
  productsSetImages({ commit }, images) {
    commit(TYPES.PRODUCT_SET_IMAGES, images)
  },
  getImageSuggestions({ dispatch }, obj) {
    return services.getImageSuggestions(obj).then((response) => {
      dispatch('imageSuggestionSetParams', {
        searchValue: obj.searchValue,
        searchBy: obj.searchBy,
        src: obj.src,
        images: response.data.images
      })
    })
  },
  syncImages({ dispatch }, id) {
    return services.syncImage(id).then((res) => {
      dispatch('setMessage', {
        type: 'success',
        message: 'Images have been processed for syncing'
      })
      return res;
    })
  },
  uploadProductImage({ dispatch }, obj) {
    return services.uploadProductImage(obj).then((response) => {
      dispatch('productsAddImage', response.data.images)
    })
  },
  addImageFromUrl({ dispatch }, obj) {
    return services.addImageFromUrl(obj).then((response) => {
      dispatch('productsAddImage', [response.data.image])
    })
  },
  imageSuggestionSetParams({ commit }, obj) {
    commit(TYPES.IMAGE_SUGGESTION_SET_PARAMS, obj)
  },
  productsAddImage({ commit }, obj) {
    commit(TYPES.PRODUCTS_ADD_IMAGE, obj)
  },
  getProductImageSuggestions({ dispatch }, id) {
    return services.getProductImageSuggestions(id).then((response) => {
      return response;
    })
  }
}

export default { module: {
  
  state,
  mutations,
  actions
} }
