import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cb0f098"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "company-item-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_office_building = _resolveComponent("office-building")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_check = _resolveComponent("check")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "companies-list",
    style: {"background":"#fff","color":"#000"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.companies, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          id: `company-id-${item.company.id}`,
          key: index,
          class: _normalizeClass(["company-list-item", `company-hash-${index}`]),
          onClick: ($event: any) => ($options.handleChangeCompany(item.company.id))
        }, [
          (item.company.logoUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.company.logoUrl
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_office_building)
                ]),
                _: 1
              })),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.company.name || ''), 1),
          (item.company.id == $props.currentCompany.id)
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 2,
                class: "el-icon--right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_check, { color: "#009688" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      }), 128)),
      ($data.companies.length == 0)
        ? (_openBlock(), _createBlock(_component_el_alert, {
            key: 0,
            closable: false,
            outline: "",
            type: "error",
            title: "No companies found"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}