import { http } from '@/utils/plugins/http';

export const index = (per_page_count, page_no, history_type) => (
    http.get(`/background_jobs?per_page_count=${per_page_count}&page_no=${page_no}&history_type=${history_type}`)
)

export const deleteJob = (id) => (
    http.delete(`/background_jobs/${id}`)
)

export const jobTypes = () => (
    http.get(`/background_jobs/history_types`)
)