import * as TYPES from '../../store/types'
import * as services from './company.services'
import { get } from 'lodash'

const emptyCompanyObject = {
  role_name: '',
  company: { id: null, name: '', email: '', logo_url: '', website: '' },
}

const state = {
  list: [],
  companiesList: {
    pagination: {},
    list: [],
  },
  pagination: {},
  users: [],
  roles: [],
  loadingCurrentCompany: false,
  loadingCompanies: false,
  currentCompany: emptyCompanyObject,
  newCompanyId: {},
}

const mutations = {
  [TYPES.COMPANIES_SET_DATA](_state, obj) {
    _state.list = obj.companies
  },
  [TYPES.COMPANIES_LIST_SET_DATA](_state, obj) {
    _state.companiesList.list = obj.companies
    _state.companiesList.pagination = obj.pagination
  },

  [TYPES.COMPANIES_ADD_DATA](_state, obj) {
    obj.role_name = 'Admin'
    _state.list.push(obj)
  },

  [TYPES.COMPANIES_EDIT_DATA](_state, obj) {
    const newState = { ..._state }
    newState.list.filter((company, i) => {
      if (company.id === obj.company.id) {
        newState.list[i] = obj.company
      }
      return
    })
    _state = newState
  },

  [TYPES.COMPANIES_SET_USERS](_state, obj) {
    _state.users = obj.users
  },

  [TYPES.COMPANIES_SET_LOADING](_state, payload) {
    _state.loadingCompanies = payload
  },

  [TYPES.COMPANIES_SET_USER_ROLES](_state, obj) {
    _state.roles = obj.roles
  },

  [TYPES.COMPANIES_SET_CURRENT_COMPANY](_state, obj) {
    const companyID = get(obj, 'company.id', null)
    localStorage.setItem('activeCompany', companyID)
    _state.currentCompany = obj
  },
  [TYPES.COMPANIES_LOADINGCURRENTCOMPANY](_state, payload) {
    _state.loadingCurrentCompany = payload
  },
  [TYPES.NEWCOMPANYID](_state, obj) {
    _state.newCompanyId = obj
  },
  [TYPES.REMOVENEWCOMPANYID](_state, obj) {
    _state.newCompanyId = {}
  },
  [TYPES.RESETCOMPANIES](_state) {
    _state.list = []
  },
}

const actions = {
  setNewCompanyID({ commit }, obj) {
    commit(TYPES.NEWCOMPANYID, {
      obj,
    })
  },
  removeNewCompanyID({ commit }) {
    commit(TYPES.REMOVENEWCOMPANYID)
  },
  getCompanies({ dispatch }) {
    return services.getCompanies().then((response) => {
      return {
        companies: response.data.companies,
        total: response.data.total,
      }
    })
  },
  getCompaniesList({ dispatch }, obj) {
    return services.getCompaniesList(obj).then((response) => {
      return response
    })
  },

  getCurrentCompany() {
    return services.getCurrentCompany().then((response) => {
      return response.data.user
    })
  },
  getCompany({ dispatch }, id) {
    return services.getCompany(id)
  },
  saveCompany({ dispatch }, obj) {
    return services.saveCompany(obj).then((response) => {
      return response.data.company
    })
  },
  saveLogoFile({ dispatch }, obj) {
    return services.saveLogo(obj).then((res) => {
      return res
    })
  },

  updateCompany({ dispatch }, obj) {
    return services.updateCompany(obj).then((response) => {
      return response.data.company
    })
  },

  deleteCompany({ dispatch }, id) {
    return services.deleteCompany(id)
  },

  changeCompany({ dispatch }, obj) {
    return services.changeCompany(obj)
  },

  getCompanyUsers({ dispatch }, id) {
    return services.getCompanyUsers(id).then((response) => {
      dispatch('companiesSetUsers', {
        users: response.data.company.users,
      })
    })
  },

  getCompanyUserRoles({ dispatch }, obj) {
    return services.getCompanyUserRoles(obj).then((response) => {
      dispatch('companiesSetUserRoles', {
        roles: response.data.data.roles,
      })
    })
  },

  setCompanyUserRole({ dispatch }, obj) {
    return services.setCompanyUserRole(obj).then((response) => {
      dispatch('setMessage', {
        type: 'success',
        message: 'User role has been changed successfully.',
      })
    })
  },

  removeCompanyUser({ dispatch }, obj) {
    return services.removeCompanyUser(obj)
  },
  addNotification({ dispatch }, obj) {
    return services.addNotification(obj)
  },
  inviteCompanyUserEmail({ dispatch }, obj) {
    return services.inviteCompanyUserEmail(obj).then((response) => {
      if (response.data.success) {
        dispatch('setMessage', {
          type: 'success',
          message: response.data.message,
        })
      } else {
        dispatch('setMessage', {
          type: 'error',
          message: response.data.message,
        })
      }
      return response.data.success
    })
  },

  companiesSetData({ commit }, obj) {
    commit(TYPES.COMPANIES_SET_DATA, obj)
  },

  resetComapniesList({ commit }) {
    commit(TYPES.RESETCOMPANIES)
  },

  companiesListSetData({ commit }, obj) {
    commit(TYPES.COMPANIES_LIST_SET_DATA, obj)
  },

  companiesAddData({ commit }, obj) {
    commit(TYPES.COMPANIES_ADD_DATA, obj)
  },

  companiesEditData({ commit }, obj) {
    commit(TYPES.COMPANIES_EDIT_DATA, obj)
  },

  companiesSetUsers({ commit }, obj) {
    commit(TYPES.COMPANIES_SET_USERS, obj)
  },

  companiesSetUserRoles({ commit }, obj) {
    commit(TYPES.COMPANIES_SET_USER_ROLES, obj)
  },
  companiesSetLoading({ commit }, payload) {
    commit(TYPES.COMPANIES_SET_LOADING, payload)
  },

  companiesSetCurrentCompany({ commit }, obj) {
    const current = obj.filter((item) => {
      return item.active === true
    })
    if (current[0]) commit(TYPES.COMPANIES_SET_CURRENT_COMPANY, current[0])
  },

  companiesSetCurrentCompanyByObject({ commit }, obj) {
    commit(TYPES.COMPANIES_SET_CURRENT_COMPANY, obj)
  },

  setCurrentCompanyLoading({ commit }, payload) {
    commit(TYPES.COMPANIES_LOADINGCURRENTCOMPANY, payload)
  },
}

const getters = {
  currentCompany: function (_state) {
    return _state.currentCompany
  },

  getNewCompanyID: function (_state) {
    return _state.newCompanyId
  },
}

export default {
  module: {
    state,
    mutations,
    getters,
    actions,
  },
}
