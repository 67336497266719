import * as services from '../service/services'
import { getMappingTemplateAttributes } from '../service/services'

const state = {
  templates: {},
}

const mutations = {
  addTemplateToState(state, template) {
    state.templates[template.id] = template
  },
  removeTemplateFromState(state, id) {
    delete state.templates[id]
  },
  changeTemplateFieldMapping(state, { templateId, fromField, newToField }) {
    state.templates[templateId] = {
      ...state.templates[templateId],
      fields: {
        ...state.templates[templateId]['fields'],
        fromField: newToField,
      },
    }
  },
}

const actions = {
  getTemplates({ dispatch }, category) {
    return services.getTemplates(category).then((response) => {
      return response
    })
  },
  getEtraAttributesFields({ dispatch }, company_id) {
    return services.getEtraAttributesFields(company_id).then((response) => {
      return response
    })
  },
  getEcompimFields({ dispatch }) {
    return services.getEcompimFields().then((response) => {
      return response
    })
  },

  getExternalSourceFields({ dispatch }, type) {
    return services.getExternalSourceFields(type).then((response) => {
      return response
    })
  },
  saveTemplate(
    { dispatch },
    { category_id, marketplace_source_id, target_id, newFieldsMapping }
  ) {
    return services
      .saveTemplate(
        category_id,
        marketplace_source_id,
        target_id,
        newFieldsMapping
      )
      .then((res) => {
        // fetch the newly saved template into the state
        return dispatch('getTemplate', {
          marketplace: 1,
          category_id,
        })
      })
      .catch((e) => {
        throw 'Failed to save template'
        console.error(e)
      })
  },
  getTemplate({ commit }, { marketplace, category_id }) {
    // get specific category template asynchronously
    getMappingTemplateAttributes(marketplace, category_id)
      .then(async (response) => {
        commit('addTemplateToState', {
          ...response.data.mapping_template,
          id: category_id,
        })
      })
      .catch((e) => {
        console.log(e)
      })
  },
  updateTemplate({ dispatch }, params) {
    return services.updateTemplate(params).then((response) => {
      return response
    })
  },
  getEditTemplate({ dispatch }, id) {
    return services.getEditTemplate(id).then((response) => {
      return response
    })
  },
  deleteMappingTemplate({ commit }, id) {
    return services
      .deleteTemplate(id)
      .then((response) => {
        commit('removeTemplateFromState', id)
        return `removed template of category ${id}`
      })
      .catch((error) => {
        console.error(error)
        throw `failed to remove template of category ${id}`
      })
  },
}


export default {
  module: {
    state,
    mutations,
    actions,
  }
}