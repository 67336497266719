import * as TYPES from '../../../../../../store/types'
import * as services from '../service/services'

const state = {
  historyLog: null
};

const mutations = {
  setHistoryLog( {state}, log) {
    state.historyLog = log
  }
}

const actions = {

  getProductChangeLog({commit}, objId){
    return services.getProductChangeLog(objId).then((res) => {
      return res;
    })
  }
}


export default { module: {
  state,
  mutations,
  actions
} }