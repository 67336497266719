import * as api from './api';
import * as c from './constants';

export const fetchBackgroundJobs = async ({commit, state}) => {
    //set loading to true
    try {
        const {per_page_count, page_no} = state.pagination;
        const {history_type} = state;
        commit(c.LOADING);
        const { data } = await api.index(per_page_count, page_no, history_type);
        if (data) {
            commit(c.SET_RESPONSE, data);
            commit(c.LOADING);
        }
    } catch (error) {
        commit(c.ERROR, 'There was an error while fetching background jobs');
    }
}

export const fetchTypes = async ({commit}) => {
    try {
        const response = await api.jobTypes();
        commit(c.SET_TYPES, response);
    } catch (error) {
        commit(c.ERROR, 'There was an error while fetching background jobs');
    }
}

export const deleteJob = async ({commit}, id) => {
    try {
        const response = await api.deleteJob(id);
        
    } catch (error) {
        
    }
}

export const setHistoryType = async ({commit, state}, payload) => {
    commit(c.SET_HISTORY_TYPE, payload);
    fetchBackgroundJobs({commit, state});
}
