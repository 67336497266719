import * as services from './userProfile.services'
import * as TYPES from '@/store/types'

const state = {
  intendedUrl:''
}

const mutations = {
  [TYPES.INTENDEDURL](_state,obj){
    _state.intendedUrl = obj;
  },

  [TYPES.REMOVEINTENDEDURL](_state,obj){
    _state.intendedUrl = '';
  }
}

const actions = {
  setProductsToDisplayLimitForUser({ dispatch }, limit) {
    return services.setProductsToDisplayLimitForUser(limit);
  },
  setIntendedUrl({commit}, obj){
    commit(TYPES.INTENDEDURL, obj)
  },
  removeIntendedUrl({commit}){
    commit(TYPES.REMOVEINTENDEDURL)
  },
  updateUser({ dispatch }, obj) {
    return services.updateUser(obj).then((response) => {
      return response.data.user
    })
  },

  uploadUserImage({ dispatch }, obj) {
    return services.uploadUserImage(obj).then((response) => {
      dispatch('setMessage', {
        type: 'success',
        message: 'Profile image has been changed successfully'
      })
      let image = response.data.user_images.filter((img) => {
        return img.active
      })
      return image
    })
  },
  getUserEmails({ dispatch }){
    return services.getUserEmails().then((response) => {
      return response;
    })
  },
  setPrimaryEmail({ dispatch },id){
    return services.setPrimaryEmail(id).then((response) => {
      return response;
    })
  },
  deleteUserEmail({dispatch},id){
    return services.deleteUserEmail(id).then((response) => {
      return response;
    })
  },
  addNewEmail({dispatch},obj){
    return services.addNewEmail(obj).then((response) => {
      return response;
    })
  }
}

let getters = {
  getIntendedUrl: function(_state){
    return _state.intendedUrl;
  }
}

export default { module: {
  state,
  mutations,
  actions,
  getters
} }
