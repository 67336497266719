
import { Search } from '@element-plus/icons-vue'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    Search,
  },
  props: ['category', 'template'],
  data: () => {
    return {
      showTabs: true,
      currentName: 'basic_info',
      loading: false,
      searchQuery: '',
      tabs: [
        {
          label: 'All',
          name: 'all',
        },
      ],
      iconMap: {
        all: 'el-icon-notebook-2',
        // variation_relationship: 'el-icon-s-open',
        // measurements: 'el-icon-s-operation',
        // stock:'el-icon-picture-outline',
        // pricing:'el-icon-sort'
      },
      fieldsEditModalVisibility: {},
      newMapping: {},
      allDestinationFields: [],
    }
  },
  computed: {
    ...mapState({
      templates: (state) => {
        return state['MappingTemplates'].templates
      },
    }),
    ...mapMutations(['changeTemplateFieldMapping']),
    allCurrentlyEditedMappingFields() {
      // returns object with both default and user defined mappings
      return {
        ...this.templates[this.category]?.default_attributes,
        ...this.templates[this.category]?.attributes,
      }
    },
    fieldsFormattedForThisComponent() {
      return {
        all: this.allCurrentlyEditedMappingFields,
      }
    },
    fieldsSearchResult() {
      if (this.searchQuery) {
        return Object.values(this.allDestinationFields).filter((item: string) =>
          this.searchQuery
            .toLowerCase()
            .split(' ')
            .some((v) => {
              return item.toLowerCase().includes(v)
            })
        )
      } else {
        return Object.values(this.allDestinationFields)
      }
    },
  },
  mounted() {
    this.setAllEditFieldsPopoversToFalse()
    this.allDestinationFields = Object.values(
      this.templates[this.category]?.default_attributes
    )
  },
  created() {
    this.setAllSourceFieldsInNewMapping()
  },
  methods: {
    handleScroll() {
      setTimeout(() => {
        let d = this.$refs['vs'].getCurrentviewDom()
        d = d[0]
        this.currentName = d.id
      }, 510)
    },
    tabClicked(data) {
      this.$refs['vs'].scrollIntoView(`#${data.name}`, 500)
    },
    getSpacedName(item) {
      return item.split('_').join(' ')
    },
    setAllEditFieldsPopoversToFalse() {
      this.fieldsEditModalVisibility = Object.fromEntries(
        Object.entries(this.allCurrentlyEditedMappingFields).map(
          ([fromField, toField], i) => [fromField, false]
        )
      )
    },
    setAllSourceFieldsInNewMapping() {
      this.newMapping = Object.fromEntries(
        Object.entries(this.allCurrentlyEditedMappingFields).map(
          ([fromField, toField], i) => [
            fromField,
            { default: toField, newToField: '' },
          ]
        )
      )
    },
    handlePopOver(item) {
      this.setAllEditFieldsPopoversToFalse()
      this.fieldsEditModalVisibility[item] = true
    },

    closeFieldPopover(item) {
      this.fieldsEditModalVisibility[item] = false
      if (this.newMapping[item].newToField != this.newMapping[item].default)
        this.$emit('newMappingConfigured', {
          fromField: item,
          newToField: this.newMapping[item].newToField,
        })
    },
  },
}
