import MappingTemplate from './MappingTemplateSources.vue'
import MappingTemplateInboundSourcesList from './inbound/InboundTemplateSourcesList.vue'
import ExportTemplate from './export-template/list.vue'

const OutboundTemplate = () =>
  import('@/app/catalog/mapping-template/export-template/create.vue')
const InboundTemplate = () =>
  import(
    '@/app/catalog/mapping-template/inbound/mapping-template-manage/InboundMappingTemplateManage.vue'
  )

export default [
  {
    name: 'products.mapping-template',
    path: '/catalog/mapping-template',
    component: MappingTemplate,
    children: [
      {
        path: 'inbound',
        props: { type: 'inbound' },
        component: { template: '<router-view/>' },
        children: [
          {
            name: 'products.mapping-template-inbound',
            path: '',
            component: MappingTemplateInboundSourcesList,
          },
          {
            name: 'products.mapping-template-inbound.create',
            path: ':marketplaceId(\\d+)/create',
            component: InboundTemplate,
          },
          {
            name: 'mapping-template.edit-inbound',
            path: ':marketplaceId(\\d+)/:id(\\d+)',
            component: InboundTemplate,
          },
        ],
      },
      {
        path: 'outbound',
        props: { category: 'outbound' },
        component: { template: '<router-view/>' },
        children: [
          {
            name: 'products.mapping-template-outbound',
            path: '',
            component: ExportTemplate,
          },
          {
            name: 'mapping-template.create-outbound',
            path: 'create/:type(.+)',
            component: OutboundTemplate,
          },
          {
            name: 'mapping-template.update-outbound',
            path: ':id(\\d+)/:type(.+)',
            component: OutboundTemplate,
          },
        ],
      },
    ],
  },
]
