
import { mapGetters, mapActions } from 'vuex';

const ProfilePic = require('@/assets/logos/account.svg')
const UserWhite = require('@/assets/logos/user-white.svg')
const ULogo = require('@/assets/logos/user.svg')
export default {
	  props:{
	    drawer:{
	      type: Boolean,
	      default: false
	    }
	  },
	  data(){
	    return {
	      isToggleProfile: false,
	      isProfileHover: false
	    }
	  },
	  watch:{
	  	'$route': function(route){
	  		if(this.$route.path === '/user/profile' && !this.isToggle){
	  			this.isToggleProfile = true;
	  			setTimeout(()=>{
	  				this.$emit('handleScroll')
	  			},500)
	  		}
	  		else
	  			this.isToggleProfile = false;
	  	}
	  },
	  mounted(){
	  	//incase profile_pic is null
	  	this.user.profile_pic =  this.user && this.user.profile_pic ? this.user.profile_pic : ProfilePic

	  	const isPathExist = this.$route.path === '/user/profile'
	  	if(isPathExist){
	  		this.isToggleProfile = isPathExist;
	  	}
	  },

	  methods:{
    ...mapActions(['signOut']),
	  	handleImgError(){
	  		this.user.profile_pic = ProfilePic;
	  	},
	  	handleProfileOpen(){
	  		this.isToggleProfile = !this.isToggleProfile;
	  		setTimeout(()=>{
	  			this.$emit('handleScroll')
	  		},500)
	  	},
    logOut(){
      this.signOut().then(()=>{
        this.$router.push({ name: 'login.index' });
      });
    	},
	  },

	  computed : {
	    ...mapGetters(['user']),
	    isToggle(){
	    	return (this.$route.path === '/user/profile' && !this.drawer) ? false : this.drawer
	    },
	    userLogo(){
	    	return this.$route.path === '/user/profile' ? UserWhite : ULogo
	    },
    username(){
      return this.user ? this.user.name:''
    	}
	  }
}
