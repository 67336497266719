<template>
  <div>
      <el-card
        v-for="(item,index) in notifications"
        :key="index"
        shadow="hover"
        class="not-item"
        :body-style="{padding:'15px 10px',lineHeight:'12px'}"
      >
          <span style="color: #000;" >{{item.title}}</span>
          <span style="color: gray;" v-html="item.description"></span>
      </el-card>
        <el-card class="no-not-msg" shadow="never" v-if="notifications.length == 0" :body-style="{padding:'15px 10px',lineHeight:'12px'}">
          No Notifications
        </el-card>
  </div>
</template>
<script type="text/javascript">
import {mapActions} from 'vuex'
import {EcGetActiveCompanyId} from '@/utils/helpers'
export default {
  data(){
    return {
      notifications:[]
    }
  },
  mounted(){
    this.fetchNotifications()
  },
  methods:{
    ...mapActions['getNotifications'],
    async fetchNotifications() {
      let activeCompanyId = EcGetActiveCompanyId()
      if (activeCompanyId) {
        let payload = {
          company_id: activeCompanyId,
          per_page_count: 100,
          page_no: 1
        }
        try {
          await this.getNotifications(payload).then((r)=>this.notifications=r)
        }
        catch (e) {
        }
      }
    }
  }
};
</script>

<style scoped>
  .not-item{
    margin-bottom: 3px;
    border: 0;
    cursor: pointer;
  }
    .no-not-msg{
    border: 0;
  }
</style>