export default {
  name: 'ClickOutside',

  beforeMount(el, binding) {
    el.clickOutsideHandler = (e: MouseEvent) => {
        if (!el.contains(e.target)) {
          binding.value(e)
        }
    }

    el.pressEscape = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        binding.value(e)
      }
    }

    document.body.addEventListener('click', el.clickOutsideHandler)
    document.body.addEventListener('keyup', el.pressEscape)
  },

  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideHandler)
    document.body.removeEventListener('keyup', el.pressEscape)
  },
}
