export default {
    jobs: (state, getters, rootState) => (
        state.response.background_jobs
    ),

    total: (state) => (
        state.response.total
    ),

    loading: (state) => (
        state.loading
    ),

    types: (state) => (
        state.types.data.history_types
    ),
    
    historyType: (state) => (
        state.history_type
    )
};