import { gql } from 'graphql-tag'

export const getInboundMappingTemplate = gql`
  query ($marketplace_id: Int!, $category_id: Int!) {
    mapping_template(
      category_id: $category_id
      from: $marketplace_id
      to: "ecompim"
    ) {
      id
      name
      source
      target
      attributes
      default_attributes
    }
  }
`

export const saveMappingTemplate = gql`
  mutation (
    $marketplace_id: Int!
    $category_id: Int!
    $target_id: String!
    $mapping_fields: JSON!
  ) {
    save_mapping_template(
      name: "template for category $category_id"
      category_id: $category_id
      source_id: $marketplace_id
      target_id: $target_id
      template_fields_attributes: $mapping_fields
    ) {
      name
      id
    }
  }
`