
import {getChildCategories} from './../services/marketplace-category.services'
export default {
  props: [
    'rootCategories',
    'marketplaceId'
  ],


  /** component data **/
  data () {
    return {
      loading: false,
      tree: [
        {
          loading:false,
          data:[]
        }
      ]
    }
  },
  mounted () {
    this.initTree();
  },
  methods: {
    initTree(){
      let categories = this.rootCategories.slice()
      if(categories)
        this.tree[0].data = categories
    },
    handleLIClick(category, parentIndex) {
      this.tree[`${parentIndex}`].data.forEach((categ) => {
        const isClickedCategory = categ === category;
        categ['isOnSelectedPath'] = isClickedCategory ? true : false;
      });
      if (category.has_children) {
        this.newTree(parentIndex, category, this.index)
      }else {
        this.$emit('categorySelected', category);
      }
    },


    async newTree(index, category) {
      this.tree.splice(index+1)
      this.tree.push({data: [],loading: true})

      setTimeout(() => {
        let elem = document.querySelector('.browse-categories');
        if(elem) {
          elem.scrollLeft = elem.scrollWidth
        }
      }, 100);

      try{
        await this.fetchCategories(category, index+1);
      } catch(error) {
        console.log(error);
      }
    },

    async fetchCategories (parentCategory, level) {
      let id = parentCategory.id;

      this.loading = true;
      if(id !== null) {
        try{
          const {data} = await getChildCategories(id, this.marketplaceId)
          //this.categories = data.child_categories;
          this.tree[level].data = data.child_categories;
          this.loading = false;
          this.tree[level].loading = false;

        } catch(error) {
          this.loading = false;
        }
      }
    }

  }
};

