export default {
	tables: [],
	keywords: '',
	keywordResults: [],
	selectedProducts: [],
	loading: false,
	saved: false,
	preview: '',
	prevewLoading: false,
	generated: false,
};