
export const MAIN_SET_TOKEN = 'MAIN_SET_TOKEN'
export const MAIN_SET_USER = 'MAIN_SET_USER'
export const MAIN_SET_HEADER_TITLE = 'MAIN_SET_HEADER_TITLE'

export const MAIN_SET_MESSAGE = 'MAIN_SET_MESSAGE'
export const MAIN_SET_FETCHING = 'MAIN_SET_FETCHING'
export const MAIN_SET_SIDEBAR_MENU = 'MAIN_SET_SIDEBAR_MENU'

export const CURRENT_PRODUCT = 'CURRENT_PRODUCT'
export const PRODUCT_SET_IMAGES = 'PRODUCT_SET_IMAGES'
export const PRODUCT_REMOVE_IMAGES = 'PRODUCT_IMAGES_REMOVE_IMAGES'
export const PRODUCTS_ADD_IMAGE = 'PRODUCTS_ADD_IMAGE'
export const PRODUCTS_IMPORT_SET_DATA = 'PRODUCTS_IMPORT_SET_DATA'
export const PRODUCTS_IMPORT_REMOVE_DATA = 'PRODUCTS_IMPORT_REMOVE_DATA'
export const COLUMN_CUSTOMIZATION_SET_DATA = 'COLUMN_CUSTOMIZATION_SET_DATA'
export const COLUMN_CUSTOMIZATION_ADD_DATA = 'COLUMN_CUSTOMIZATION_ADD_DATA'
export const COLUMN_CUSTOMIZATION_REMOVE_DATA = 'COLUMN_CUSTOMIZATION_REMOVE_DATA'
export const IMAGE_SUGGESTION_SET_PARAMS = 'IMAGE_SUGGESTION_SET_PARAMS'
export const SET_MATCHING_PRODUCTS = 'SET_MATCHING_PRODUCTS'
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES'
export const REMOVE_PRODUCT_CATEGORY = 'REMOVE_PRODUCT_CATEGORY'

export const COMPANIES_SET_DATA = 'COMPANIES_SET_DATA'
export const COMPANIES_SET_CURRENT_COMPANY = 'COMPANIES_SET_CURRENT_COMPANY'
export const COMPANIES_LIST_SET_DATA = 'COMPANIES_LIST_SET_DATA'
export const COMPANIES_ADD_DATA = 'COMPANIES_ADD_DATA'
export const COMPANIES_EDIT_DATA = 'COMPANIES_EDIT_DATA'
export const COMPANIES_SET_USERS = 'COMPANIES_SET_USERS'
export const COMPANIES_SET_USER_ROLES = 'COMPANIES_SET_USER_ROLES'
export const COMPANIES_NOTIFICATIONS = 'COMPANIES_NOTIFICATIONS'
export const COMPANIES_LOADINGCURRENTCOMPANY = 'COMPANIES_LOADINGCURRENTCOMPANY'
export const COMPANIES_SET_LOADING = 'COMPANIES_SET_LOADING'

export const NEWCOMPANYID = 'NEWCOMPANYID'
export const REMOVENEWCOMPANYID = 'REMOVENEWCOMPANYID'

export const INTENDEDURL = 'INTENDEDURL'
export const REMOVEINTENDEDURL = 'REMOVEINTENDEDURL'

// PRODUCT CATEGORY RELATED CONSTANTS.

export const AMAZON_ROOT_CATEGORY = 'AMAZON_ROOT_CATEGORY'
export const EBAY_ROOT_CATEGORY = 'EBAY_ROOT_CATEGORY'

export const AMAZON_CHILD_CATEGORY = 'AMAZON_CHILD_CATEGORY'
export const EBAY_CHILD_CATEGORY = 'EBAY_CHILD_CATEGORY'

export const SET_CATALOG_SOURCES ='SET_CATALOG_SOURCES'

export const ADD_VARIATIONS = 'ADD_VARIATIONS';
export const GRID_SETDATA = 'GRID_SETDATA';
export const GRID_SETDATACOUNT = 'GRID_SETDATACOUNT';
export const SET_BULK_UPDATE_CATEGORY = 'BULK_UPDATE_CATEGORY';
export const REMOVE_BULK_UPDATE_CATEGORY = 'REMOVE_BULK_UPDATE_CATEGORY';
export const CLEAR_BULK_UPDATE_CATEGORY = 'CLEAR_BULK_UPDATE_CATEGORY';
export const SETPRODUCTCOLUMNCUSTOMIZATIONVISIBLECOLUMNS = 'SETPRODUCTCOLUMNCUSTOMIZATIONVISIBLECOLUMNS'
export const SET_JOB_ID = 'SET_JOB_ID'
export const SET_SAVE_SEARCH = 'SET_SAVE_SEARCH'
export const RESETCOMPANIES = 'RESETCONPANIES'
export const SETSEARCHSUGGESTION_LIST = 'SETSEARCHSUGGESTION_LIST'
export const RESETSEARCHSUGGESTION_LIST = 'RESETSEARCHSUGGESTION_LIST'
export const TOGGLE_TRIGGER_STATUS = 'TOGGLE_TRIGGER_STATUS'
export const SET_ELOADING_CLOSE_STATUS = 'SET_ELOADING_CLOSE_STATUS'
