
	import breadcrum from '@/components/breadcrum.vue';
	export default {
		name: "NotFound",
		props: {
			showBreadcrumb: {
				type: Boolean,
				default: true,
			}
		},
		data() {
			return {
				links: {
					navs: [{ to: { path: '/dashboard' }, id:'nav-links-notFound', title: 'Dashboard' }],
					current: 'Not Found'
				},
			}
		},
		components: {
			breadcrum
		}
	};
