
    export default {
        props: {
        	links: {
        		type: Object,
                default: () => {},
                 id:'links-breadcrum',
        	},
        	isProductPage: {
        		type: Boolean,
        		default: false
        	}
        },
    }
