import { apolloClient as gclient } from '@/utils/vue-apollo'
import { gql } from 'graphql-tag'

export const getMaketSources = () => {
  return gclient.query({
    query: gql`query  { sources }`
  })
}
//get market sources keyed by id
//e.g. {name:'Amazon'}}
export const marketSourcesById = async () => {
  const response = await getMaketSources()
  if (response && response.data) {
    const t = {}
    response.data.sources.map((s) => t[s.id] = s)
    return t
  }
  return {}
}
//get single market source by source_id
export const marketSourceById = async (id) => {
  const response = await marketSourcesById()
  if (response && response[id]) {
    return response[id]
  }
  return null
}

export const getCatalogSourcesIds = () => {
  return gclient.query({
    query: gql`query {
			catalog_sources
		  }`
  })
}

//get list of catalog sources
//e.g. [{name:'Amazon'},{name:'Ebay'}]
export const getCatalogSources = async () => {
  let response = await getCatalogSourcesIds()
  const ids = response.data.catalog_sources.ids

  response = await getMaketSources()
  if (response) {
    const sources = response.data.sources.filter((s) => ids.indexOf(s.id) > -1)
    return new Promise((resolve) => {
      resolve(sources)
    })
  }
  else {
    return new Promise((resolve) => {
      resolve('')
    })
  }
}
//get list of catalog sources keyed by id
//e.g. {1:{name:'Amazon'},3:{name:'Ebay'}}
export const getCatalogSourcesKeyedById = async () => {
  const sources: any = await getCatalogSources()
  if (sources) {
    const t = {}
    sources.map((s) => t[s.id] = s)
    return new Promise((resolve) => {
      resolve(t)
    })

  }
  else {
    return new Promise((resolve) => {
      resolve('')
    })
  }

}

const sourceByMarketplaceId = (sources, marketplaceId)=> sources.find(source => source.marketplaces.some(marketplace => marketplace.id == marketplaceId))

export const formatters = {
  sourcesGroupedById : (sources : {id: number}[]) => {
    if (sources) {
      const t = {}
      sources.map((s) => t[s.id] = s)
      return t
    }
    return null;
  },
  sourceByMarketplaceId(sources, marketplaceId) {
    return sourceByMarketplaceId(sources, marketplaceId);
  },
  marketplaceById(sources, marketplaceId) {
    const marketSource = sourceByMarketplaceId(sources, marketplaceId);
    return marketSource.marketplaces.find(marketplace => marketplace.id == marketplaceId)
  }
}

