import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "newcat__wrapper",
  style: {"border":"1px solid #ddd"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_debounce = _resolveDirective("debounce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: "10" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { md: 8 }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_el_input, {
              modelValue: $data.categorySearchTerm,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.categorySearchTerm) = $event)),
              class: "searchcategory__input",
              placeholder: "Write category search term"
            }, {
              suffix: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(["el-input__icon", `el-icon-${$data.searchIcon}`]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.clearInputField && $options.clearInputField(...args)))
                }, null, 2)
              ]),
              _: 1
            }, 8, ["modelValue"])), [
              [_directive_debounce, $options.searchTermDebounce, "1000ms"],
              [_directive_debounce, $options.searchTermChange, "1ms"]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { md: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $data.selectedCatalogSource,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.selectedCatalogSource) = $event)),
              placeholder: "Select Source",
              filterable: "",
              style: {"display":"block"},
              "no-match-text": "Not Found",
              disabled: $options.catalogSourceSelectionDisable,
              onChange: $options.onCatalogSourceChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.catalog_sources, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.name,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "disabled", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { md: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $data.selectedMarketPlace,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.selectedMarketPlace) = $event)),
              style: {"display":"block"},
              filterable: "",
              placeholder: "Select Marketplace",
              "no-match-text": "No Category Found",
              "default-first-option": true,
              disabled: $options.marketplaceSelectionDisable,
              onChange: $options.onRegionChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.marketPlaces, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.locale_code + ' (' + item.locale_name + ')',
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "disabled", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}