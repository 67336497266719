import { apolloClient as gclient } from '@/utils/vue-apollo'
import { gql } from 'graphql-tag'



export const getCategorySuggestions = (term, marketplace_id) =>{
  return gclient.query({
    query:gql`
		query($term:String!,$marketplace_id:Int! ) {
		  categories_suggestions (
			term:$term,
			marketplace_id:$marketplace_id
		  )
		}`,
    variables: {
      term:term,
      marketplace_id:+marketplace_id//changed to integer
    },
    fetchPolicy: 'no-cache'
  })
}

export const getTopCategories = (source_id) =>{
  return gclient.query({
    query:gql`query($source_id:Int!) {
			top_categories(source_id:$source_id)
		  }`,
    variables: {
      source_id:+source_id
    },
    fetchPolicy: 'no-cache'
  })
}

export const getProductCategorySuggestions = (product_id, marketplace_id) =>{
  return gclient.query({
    query:gql`
		query($product_id:Int!, $marketplace_id:Int! ) {
		  product_category_suggestions (
			product_id:$product_id,
			marketplace_id:$marketplace_id
		  )
		}`,
    variables: {
      product_id:+product_id,
      marketplace_id:+marketplace_id
    },
    fetchPolicy: 'no-cache'
  })
}

export const getRootCategories = (marketplace_id) =>{
  return gclient.query({
    query:gql`
		query($marketplace_id:Int! ) {
		  root_categories (
			marketplace_id:$marketplace_id
		  )
		}`,
    variables: {
      marketplace_id:+marketplace_id
    },
    fetchPolicy: 'no-cache'
  })
}

export const getChildCategories = (cat_id, marketplace_id) =>{
  return gclient.query({
    query:gql`
		query($id:Int!, $marketplace_id:Int! ) {
		  child_categories (
			id:$id,
			marketplace_id:$marketplace_id
		  )
		}`,
    variables: {
      id:+cat_id,
      marketplace_id:+marketplace_id
    },
    fetchPolicy: 'no-cache'
  })
}

export const getCatalogSources = () =>{
  return gclient.query({
    query:gql`query {
			catalog_sources
		  }`
  })
}
