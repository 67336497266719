export const MenuItems = [{
  icon: 'apps',
  text: 'Dashboard',
  to: '/',
  metas: {
    roles : ['User', 'Admin']
  }
},
{
  icon: 'book-open',
  text: 'Catalog',
  model: false,
  children: [
    {
      icon: 'box',
      text: 'Products' ,
      to: '/catalog/products' ,
      metas: {
        roles : ['User', 'Admin']
      }
    },

    {
      icon: 'game-structure',
      text: 'Variations' ,
      to: '/catalog/variations',
      metas: {
        roles : ['User', 'Admin']
      }
    },
    {
      icon: 'file-upload-alt',
      text: 'Mapping Template' ,
      to: '/catalog/mapping-template/inbound',
      metas: {
        roles : ['User', 'Admin']
      }
    }]
},
{
  icon: 'setting',
  text: 'Settings',
  model: false,
  children: [
    {
      icon: 'wrench',
      text: 'Config' ,
      to: '/settings/config/',
      metas: {
        roles : ['Admin']
      }
    },
    {
      icon: 'building',
      text: 'Companies' ,
      to: '/companies/',
      metas: {
        roles : ['User', 'Admin']
      }
    },
    {
      icon: 'plus-square',
      text: 'Create New Company' ,
      to: '/company/new',
      metas: {
        roles : ['User', 'Admin']
      }
    }]
},
{
  icon: 'constructor',
  text: 'Background Jobs' ,
  to: '/background-jobs/',
  metas: {
    roles : ['User', 'Admin']
  }
}];