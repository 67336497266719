import { defineAsyncComponent } from 'vue'
declare let require: any
/**
* Components are lazy-loaded
* http://router.vuejs.org/en/advanced/lazy-loading.html
*/
// eslint-disable-line global-require
const Company = defineAsyncComponent(() => import('./company.vue'))
const Invite = defineAsyncComponent(() => import('./invite.vue'))
const Configuration = defineAsyncComponent(() => import('./step4.vue'))
const Import = defineAsyncComponent(() => import('./import.vue'))

export default [
  {
    name: 'setup.company',
    path: '/setup/company',
    component: Company,

  },
  {
    name: 'setup.invite',
    path: '/setup/invite',
    component: Invite,

  },
  {
    name: 'setup.configuration',
    path: '/setup/configuration',
    component: Configuration,

  },
  {
    name: 'setup.import',
    path: '/setup/import',
    component: Import,

  }
]
