import { http } from '@/utils/plugins/http'

export const addSizeTableValues = (payload) => (
	http.post(`/products/add_size_table_values`, payload) //POST /api/v1/products/create_size_table_image
)

export const sizeTableImage = (payload) => (
	http.post(`products/size_table_image?ids=${payload.ids}&table_index=${payload.table_index}`, payload.formData)
)

export const generateSizeTableImage =(payload) => (
	http.post(`/products/generate_size_table_images`, payload)
)

export const extraAttributeSuggestions = (id) => (
	http.get(`/companies/${id}/extra_attributes_suggestions`)
)

export const singlePreview = (id) => (
	http.get(`/products/${id}/size_tables_data`)
)

export const bulkPreview = (payload) => (
	http.post(`/products/bulk_size_tables_data`, payload)
)

export const extraAttributeValues = (payload) => (
	http.post(`/products/suggestions_by_extra_attribute_for_products?extra_attributes_name=${payload.extra_attribute}`, payload.body)
)

export const uploadImageViaUrl = (payload) => (
	http.post(`/products/size_table_image_from_url?table_index=${payload.table}&url=${payload.url}`, payload.body)
)
