import { cookies, local } from 'brownies'

export function localStorageSetItem(label, obj) {
  obj = JSON.stringify(obj)
  local[label] = obj
  cookies[label] = obj
}

export function localStorageGetItem(label) {
  const string = local[label]
  return JSON.parse(string)
}

export function localStorageSetToken(token) {
  local['token'] = token
  cookies['token'] = token
}
