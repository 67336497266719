
import MainNavbar from '../navbar/MainNavbar.vue'
import SidebarMain from '../sidebar/SidebarMain.vue'
import MainLoaderSpinner from '../partials/MainLoaderSpinner.vue'
import { localStorageGetItem } from '@/utils/local'

import { mapGetters, mapActions } from 'vuex'
import { get } from 'lodash'
export default {
  name: 'MainLayout',
  components: {
    MainNavbar,
    SidebarMain,
    MainLoaderSpinner,
  },
  data() {
    return {
      drawer: false,
      loading: false,
      isAuthorized: false,
    }
  },
  async mounted() {
    this.setCurrentCompanyLoading(true)
    this.loading = true
    await this.getCurrentCompany()
      .then((data) => {
        let user = localStorageGetItem('user') || {}
        this.setCurrentCompanyLoading(false)
        user.role = data.role_name
        user.hasCompany = data.company ? true : false
        this.companiesSetCurrentCompanyByObject(data)
        if (!data.company) {
          this.setUser(user)
          this.$router.push({ name: 'setup.company' })
        } else {
          user.activeCompanyId = data.company.id
          user.activeCompanyName = data.company.name
        }
        this.setUser(user)
        this.loading = false
        this.isAuthorized = true
      })
      .catch((e) => {
        this.setCurrentCompanyLoading(false)
        this.loading = false
        this.isAuthorized = e.response?.status != 401
        this.$message({
          type: 'info',
          message: 'Error while fetching company',
        })
      })
    if (this.$route.path === '/user/profile') {
      this.handleScroll()
    }
  },
  methods: {
    ...mapActions([
      'companiesSetCurrentCompanyByObject',
      'setCurrentCompanyLoading',
      'getCurrentCompany',
      'setUser',
    ]),
    handleDraw() {
      this.drawer = !this.drawer
    },
    drawerToggled(drawer) {
      this.drawer = drawer
    },
    handleScroll() {
      let container = this.$el.querySelector('#profile-scroll')
      if (container) container.scrollTop = container.scrollHeight
    },
  },
}
