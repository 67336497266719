import gql from 'graphql-tag'
const StateFields = gql`
    fragment stateFields on CatalogProductsSearchState {
      stateId
      name
      criteria
      isCurrent
      searchState
    }
  `

export const CatalogProductsSearchStates = gql`
  query CatalogProductsSearchStates($criteria: String!,$config: JSON = {} ) {
    catalogProductsSearchStates(criteria: $criteria, config : $config) {
      stateId
      name
      isCurrent
    }
  }
`
export const CatalogProductsCurrentSearchState = gql`
  query CatalogProductsCurrentSearchState($criteria: String!,$config: JSON = {} ) {
    catalogProductsCurrentSearchState(criteria: $criteria, config : $config) {
      ...stateFields
    }
  }
  ${StateFields}
`
export const CatalogProductsDefaultSearchState = gql`
  query CatalogProductsDefaultSearchState($criteria: String!,$config: JSON = {} ) {
    catalogProductsDefaultSearchState(criteria: $criteria, config : $config) {
      name
      isCurrent
      searchState
    }
}`
export const CatalogProductsSearchStateById  = gql`
  query CatalogProductsSearchStateById($id: Int!) {
    catalogProductsSearchStateById (id: $id) {
      ...stateFields
    }
  }
  ${StateFields}
`
export const SaveCatalogProductsSearchState  = gql`
  mutation SaveCatalogProductsSearchState(
    $stateId : Int!,
    $params: JSON
  ){
    saveCatalogProductsSearchState (
      stateId : $stateId,
      params: $params
    ) {
      ...stateFields
    }
  }
  ${StateFields}
`
export const CreateCatalogProductsDefaultSearchState  = gql`
  mutation CreateCatalogProductsDefaultSearchState($criteria: String!) {
    createCatalogProductsDefaultSearchState(criteria : $criteria) {
      ...stateFields
    }
  }
  ${StateFields}
`
export const SaveCatalogProductsSearchStateAs  = gql`
  mutation SaveCatalogProductsSearchStateAs(
    $fromStateId: Int!, 
    $name: String!,
    $searchState: JSON!
    $setCurrent: Boolean = false
    ){
      saveCatalogProductsSearchStateAs(
      fromStateId: $fromStateId, 
      name: $name,
      searchState: $searchState,
      setCurrent: $setCurrent
      ) {
      ...stateFields
    }
  }
  ${StateFields}
`
export const CatalogProductsSearchStateSetCurrent  = gql`
  mutation CatalogProductsSearchStateSetCurrent(
    $stateId: Int!,
    $criteria: String
    ){
    catalogProductsSearchStateSetCurrent(
      stateId: $stateId, 
      criteria: $criteria) {
      ...stateFields
    }
  }
  ${StateFields}
`

export const CatalogProductsSearchStateReset  = gql`
  mutation CatalogProductsSearchStateReset(
     $stateId: Int!,
     $action: String!
    ){
    catalogProductsSearchStateReset (
      stateId: $stateId,
      action: $action
    ) {
      ...stateFields
    }
  }
  ${StateFields}
`
