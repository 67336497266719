
export default {
  props: ['categories', 'loading'],
  methods:{
    categorySelected(category){
      this.$emit('categorySelected', category)
    }
  }

}
