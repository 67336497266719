<template>
    <div
        v-loading.fullscreen.lock="loading"
        element-loading-text="Loading..."
        element-loading-custom-class="main-loader-spinner">
    </div>
</template>
<script type="ts">
export default {
  name:'MainLoaderSpinner',
  props:{
    loading: {
      default:false,
      type: Boolean
    }
  },
  mounted(){
    console.log(this.loading)
  }
}
</script>
<style>
 .main-loader-spinner {
     background: #eeeded;
 }
 .main-loader-spinner .el-loading-spinner {
     width: 300px;
     background: #fff;
     left:0;
     right:0;
     margin:auto;
     padding-top:20px;
     padding-bottom: 20px;
 }

  .main-loader-spinner .el-loading-text,.main-loader-spinner .el-loading-spinner .path{
      color:#000;
      font-size:16px;
  }
  .main-loader-spinner .el-loading-spinner .path{
      stroke:#000;
      stroke-width:3px;
  }
</style>