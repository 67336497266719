import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59248da0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "browse-categories" }
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_unicon = _resolveComponent("el-unicon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tree, (cat_node, parentIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: parentIndex,
        class: "item",
        style: {"height":"100%"},
        "data-cy": "browse-category-level"
      }, [
        _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cat_node.data, (category) => {
            return (_openBlock(), _createElementBlock("li", {
              key: category.id,
              class: _normalizeClass({'selected': category.isOnSelectedPath }),
              "data-cy": "browse-category-item",
              onClick: ($event: any) => ($options.handleLIClick(category, parentIndex))
            }, [
              _createElementVNode("span", null, _toDisplayString(category.category_name), 1),
              _createVNode(_component_el_unicon, {
                style: {"float":"right"},
                name: category.has_children ? 'angle-right-b' : 'plus',
                width: "12px"
              }, null, 8, ["name"])
            ], 10, _hoisted_3))
          }), 128))
        ])), [
          [_directive_loading, cat_node.loading]
        ])
      ]))
    }), 128))
  ]))
}