<template>
	<div v-bind:class="{'sidebar-toggler':true,drawer: drawer}" @click="toggleDrawer" >
		<el-unicon
		  :name="`angle-${drawer ? 'right': 'left'}-b`"
		  fill="#fff"
		  width="15px"
		/>
	</div>
</template>
<script type="text/javascript">
export default {
	  name: 'SidebarToggler',
  data(){
    return {
      drawer:false
    }
  },
  methods:{
    toggleDrawer(){
      this.drawer = !this.drawer
      this.$emit('drawerToggled',this.drawer)
    }
  }
};
</script>

<style scoped>
	.sidebar-toggler {
		position: fixed;
    top: 16px;
    z-index: 1111;
    left: 262px;
    background: #151941;
    text-align: center;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 0;
    transition: all 0.2s;
	}
	.sidebar-toggler.drawer{
		left: 61px;
		transition: all 0.2s;
	}
	.sidebar-toggler .uil {
		text-align: center;
		margin: 7px;
	}
</style>