// https://vuex.vuejs.org/en/mutations.html

import { ElNotification as Notification } from 'element-plus';

import * as TYPES from './types';
// declare var PNotify;

/* eslint-disable no-param-reassign */
export default {
  [TYPES.MAIN_SET_TOKEN](state, obj) {
    state.token = obj.token
  },
  [TYPES.MAIN_SET_USER](state, user) {
    state.user = user
  },
  [TYPES.MAIN_SET_HEADER_TITLE](state, title) {
    state.headerTitle = title
  },
  [TYPES.MAIN_SET_FETCHING](state, obj) {
    state.fetching = obj.fetching
  },
  [TYPES.MAIN_SET_MESSAGE](state, obj) {
    if (obj.type) {
      Notification({
        title: obj.type,
        message: obj.message,
        type: obj.type.toLowerCase()
      });
    }

  },
  [TYPES.MAIN_SET_SIDEBAR_MENU](state, obj) {
    state.sidebarMenu = obj.sidebarMenu
  },
  [TYPES.COMPANIES_NOTIFICATIONS](state, obj) {
    state.notifications = obj.data.notifications
  },
  [TYPES.CURRENT_PRODUCT](state, obj) {
    state.currentProduct = obj
  }
}
