import { http } from '@/utils/plugins/http'
import {apolloClient as gclient} from '@/utils/vue-apollo'
import gql from 'graphql-tag'

export const addProductCategory = ({ productId, catalogSource, categoryId }) =>
  http.post(
    `/products/add_category?product_id=${productId}&catalog_source_id=${catalogSource}&source_category_id=${categoryId}`
  )
export const removeProductCategory = (categoryId) =>
  http.delete(`/categories/${categoryId}`)

export const getCatalogSources = () => http.get('catalog_sources')
export const getEbaySpecifics = (id, product_id) =>
  http.get(`ebay/categories/${id}/category_specifics/${product_id}`)
export const getAmazonRefinements = (categoryId) =>
  http.get(`amazon/categories/${categoryId}`)
export const getAmazonCategoryGroupAttrs = (categoryId, group, productId) =>
  http.get(`amazon/categories/${categoryId}/group/${group}/attrs/${productId}`)
// export const getAmazonCategoryGroups = (categoryId) => http.get(`amazon/categories/${categoryId}/groups`);//todo remove this soon
export const getEbaySiteCodes = () =>
  http.get('ebay/categories/ebay_site_codes')
export const updateProductAttributes = (productId, extra_attributes) =>
  http.put(`products/${productId}/update_product_attributes`, extra_attributes)
export const updateCategoryAttributes = (extra_attributes, id) =>
  http.post(`products/${id}/add_attributes`, extra_attributes)
export const changeProductCategory = ({
  productId,
  catalogSource,
  categoryId,
}) =>
  http.put(
    `products/${productId}/change_category?source_category_id=${categoryId}&catalog_source_id=${catalogSource}`
  )
export const getAttributes = () => http.get('/catalog_sources')

export const fetchProductCategories = (productId) =>
  gclient.query({
    query: gql`
      query ($productId: Int!) {
        product_categories(product_id: $productId)
      }
    `,
    variables: {
      productId: +productId, //changed to integer
    },
    fetchPolicy: 'no-cache',
  })

export const getCatalogCategoryGroups = (categoryId) =>
  gclient.query({
    query: gql`
      query ($categoryId: Int!) {
        catalog_category(category_id: $categoryId)
      }
    `,
    variables: {
      categoryId: +categoryId, //changed to integer
    },
    fetchPolicy: 'no-cache',
  })

export const getCatalogCategoryAttributes = ({
  sourceId,
  categoryId,
  group = '',
  productId = 0,
}) => {
  const params = `category_id:$category_id, source_id:$source_id ${
    group ? ',group:$group' : ''
  } ${productId ? ',product_id:$product_id' : ''}`
  const ptypes = `$category_id:Int!, $source_id:Int! ${
    group ? ',$group:String' : ''
  } ${productId ? ',$product_id:Int!' : ''}`
  return gclient.query({
    query: gql`
        query(${ptypes} ) {
          catalog_category_attributes (
            ${params}
          )
    }`,
    variables: {
      category_id: +categoryId,
      source_id: +sourceId,
      group,
      product_id: +productId,
    },
    fetchPolicy: 'no-cache',
  })
}
