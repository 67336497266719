import * as services from './auth.services'
import {EcGetCurrentUser} from "@/utils/helpers"

const state = {
}

const mutations = {
}

const actions = {
  login({}, obj) {
    return services.login(obj);
  },
  afterSignedOut({dispatch}){
    dispatch('setToken', '')
    dispatch('setUser', {})
    dispatch('resetComapniesList');
  },
  signOut({dispatch}){
    let user = EcGetCurrentUser();
    let email = user?user.email:''
    if(email) {
      let obj = {
        user: {
          email
        }
      };
      return services.signOut(obj).then(()=>{
          dispatch('afterSignedOut')
      }).catch(()=>{
          dispatch('afterSignedOut')
      });
    }
    else {
      dispatch('afterSignedOut')
      return Promise.resolve('done');
    }
  },

  socialLogin({ dispatch }, obj) {
    return services.socialLogin(obj).then((response) => {
      //set auth token
      dispatch('setToken', response.headers.authorization)
      //set user
      dispatch('setUser', response.data.user)
    })
  },

  forgotPassword({ dispatch }, obj) {
    return services.forgotPassword(obj).then((response) => {
    }).then((res) => {
      dispatch('setMessage',{ type: 'Success', message: 'If your email exsist with us you should recieve email with password reset link.' })
    })
  },

  resetPassword({ dispatch }, obj) {
    return services.resetPassword(obj).then((response) => {
      return true
    }).catch((err) => {
      return false
    })
  },
  
  inviteRegister({ dispatch }, obj) {
    return services.inviteRegister(obj).then((response) => {
      dispatch('setToken', response.headers.authorization);
        dispatch('setUser', response.data.user);
        // console.log('test');
        //dispatch('setMessage', { type: 'error', message: [] })
    })
  },
  checkUserNameAvailabality({ dispatch }, obj) {
    return services.checkUsername(obj);
  },
  register({ dispatch }, obj) {
    return services.register(obj).then((response) => {
      dispatch('setToken', response.headers.authorization)
      dispatch('setUser', response.data.user)
      return true
    })
  }
}



export default { module: {
  state,
  mutations,
  actions
} }
