import * as c from './constants';

export default {
    [c.SET_RESPONSE]: (state, payload) => (
        state.response = payload
    ),

    [c.LOADING]: (state) => (
        state.loading = !state.loading
    ),

    [c.ERROR]: (state, payload) => (
        state.error = payload
    ),

    [c.SET_TYPES]: (state, payload) => (
        state.types = payload
    ),

    [c.SET_HISTORY_TYPE]: (state, payload) => (
        state.history_type = payload
    ),

    changePagination: (state, new_page) => (
        state.pagination.page_no = new_page
    )
}