import { defineAsyncComponent } from 'vue'
declare let require: any
/**
* Components are lazy-loaded
* http://router.vuejs.org/en/advanced/lazy-loading.html
*/
// eslint-disable-line global-require
const Auth = defineAsyncComponent(() => import('./AuthPage.vue'));
const ForgotPassword = defineAsyncComponent(() => import('./forgot-password/ForgotPassword.vue'));
const ResetPassword =defineAsyncComponent(() => import('./reset-password/ResetPassword.vue'));
const Invitation = defineAsyncComponent(() => import('./invitation/Invitation.vue'));

export default [
  {
    name: 'login.forgotPassword',
    path: '/login/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'login.resetPassword',
    path: '/users/password/edit',
    component: ResetPassword
  },
  {
    name: 'login.index',
    path: '/login',
    component: Auth
  },
  {
    name: 'register.index',
    path: '/register',
    component: Auth
  },
  {
    name: 'register.invitation',
    path: '/register/invitation',
    component: Invitation
  }
]
