
import { ElLoading as Loading } from 'element-plus'
import { mapActions } from 'vuex'
import graphql from '@/graphql';
import InboundTemplateSourceRow from './InboundTemplateSourceRow.vue'
import InboundMappingTemplateManage from './mapping-template-manage/InboundMappingTemplateManage.vue'
import { formatters } from '@/utils/common/sources'

const formatInboundSources = (inbound_sources, sources) => {
  let output = []
  for (let s of inbound_sources) {
    let market = sources[s.id]
    let marketplace = null
    if (s.marketplace_id && market?.marketplaces) {
      for (let m of market.marketplaces) {
        if (m.id == s.marketplace_id) {
          marketplace = m
          break
        }
      }
    }
    output.push({
      market,
      marketplace,
      categoryMappping: s.categoryMapping,
      templates: s.templates,
    })
  }
  return output
}
export default {
  components: {
    InboundTemplateSourceRow,
    InboundMappingTemplateManage,
  },
  data: () => {
    return {
      managingMappingSource: null
    }
  },
  apollo : {
    sources : {
      query  : graphql.query.GetSources
    },
    mapping_templates : {
      query : graphql.query.GetMappingTemplates,
      fetchPolicy : 'no-cache'
    }
  },
  computed: {
    mappingsList(){
      if(this.mapping_templates && this.sourcesById) {
        return formatInboundSources(
          this.mapping_templates.inbound_sources,
          this.sourcesById
        )
      }
      return [];
    },
    sourcesById(){
      return formatters.sourcesGroupedById(this.sources);
    },
    mapping(){
      return this.mapping_templates?.mapping || {};
    }
  },

  methods: {
    ...mapActions({ deleteTemplate: 'deleteMappingTemplate' }),

    deleteMappingTemplate(category_id) {
      this.$confirm(
        'Are you sure you want to delete this template?',
        'Warning',
        {
          confirmButtonText: 'YES',
          cancelButtonText: 'NO',
          type: 'warning',
        }
      )
        .then(() => {
          let loading = Loading.service({
            fullscreen: true,
            text: 'Deleting template.',
          })
          this.deleteTemplate(category_id)
            .then(() => {
              this.$apollo.queries.mapping_templates?.refetch?.()
            })
            .catch((error) => {
              console.log(error)
            })
          loading.close()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          })
        })
    },
  },
}
