import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fa162b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "loading category-selection-wrapper",
  "element-loading-text": "Loading Categories"
}
const _hoisted_2 = { class: "tabs-container" }
const _hoisted_3 = { class: "tab__label flex-center-item" }
const _hoisted_4 = { class: "tab__label flex-center-item" }
const _hoisted_5 = { class: "tab__label flex-center-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_category_selector_header = _resolveComponent("product-category-selector-header")!
  const _component_category_search_results = _resolveComponent("category-search-results")!
  const _component_bell_filled = _resolveComponent("bell-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_top_categories_list = _resolveComponent("top-categories-list")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_unicon = _resolveComponent("el-unicon")!
  const _component_suggested_categories = _resolveComponent("suggested-categories")!
  const _component_View = _resolveComponent("View")!
  const _component_browse_categories = _resolveComponent("browse-categories")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_product_category_selector_header, {
      ref: "cat_selector_header",
      "default-catalog-source-id": $props.defaultCatalogSourceId,
      "default-catalog-source-marketplace-id": $props.defaultCatalogSourceMarketplaceId,
      onSearchTermChange: $options.searchTermChange,
      onSearchTermDebounce: $options.searchTermDebounce,
      onMarketPlaceChange: $options.marketPlaceChange
    }, null, 8, ["default-catalog-source-id", "default-catalog-source-marketplace-id", "onSearchTermChange", "onSearchTermDebounce", "onMarketPlaceChange"]),
    _withDirectives(_createVNode(_component_category_search_results, {
      ref: "csr",
      "marketplace-id": $data.selected.marketplace_id,
      "search-results": $data.searchResults,
      "search-roots": $data.searchRoots,
      "search-loading": $data.searchLoading,
      "element-loading-text": "Loading search results",
      onCategorySelected: $options.categorySelected
    }, null, 8, ["marketplace-id", "search-results", "search-roots", "search-loading", "onCategorySelected"]), [
      [_vShow, $data.showSearchBox],
      [_directive_loading, $data.searchLoading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: $data.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.activeName) = $event)),
        class: "categories__tab amazon-cat-tab",
        onTabChange: $options.tabChanged
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            name: "top_categories",
            class: "tab-pane"
          }, {
            label: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_el_icon, { size: "20" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bell_filled)
                  ]),
                  _: 1
                }),
                _createTextVNode("Top Categories ")
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_top_categories_list, {
                "top-categories": $data.topCategories,
                onCategorySelected: $options.categorySelected
              }, null, 8, ["top-categories", "onCategorySelected"])
            ]),
            _: 1
          }),
          (!$props.hideSuggestionTab)
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                name: "categories_suggestions",
                class: "tab-pane"
              }, {
                label: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_el_unicon, {
                      fill: "currentColor",
                      name: "telegram-alt",
                      width: "15px"
                    }),
                    _createTextVNode(" Suggestions ")
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_suggested_categories, {
                    "suggested-categories": $data.productSuggestedCategories,
                    onCategorySelected: $options.categorySelected
                  }, null, 8, ["suggested-categories", "onCategorySelected"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_tab_pane, {
            name: "browse_categories",
            class: "tab-pane"
          }, {
            label: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_el_icon, { size: "20" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_View)
                  ]),
                  _: 1
                }),
                _createTextVNode("Browse Categories ")
              ])
            ]),
            default: _withCtx(() => [
              ($data.rootCategories)
                ? (_openBlock(), _createBlock(_component_browse_categories, {
                    key: 0,
                    "root-categories": $data.rootCategories,
                    "marketplace-id": $data.selected.marketplace_id,
                    onCategorySelected: $options.categorySelected
                  }, null, 8, ["root-categories", "marketplace-id", "onCategorySelected"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabChange"])
    ], 512), [
      [_vShow, !$data.showSearchBox]
    ])
  ])), [
    [_directive_loading, $data.loading]
  ])
}