import * as TYPES from '../../store/types'
import * as services from './services'

const actions = {
    getRoles() {
        return services.getRoles();
    },
    inviteUser({ dispatch }, obj) {
        return services.inviteUser(obj);
    },
    getCompanyUsersInvite({ dispatch }, id) {
        return services.getCompanyUsers(id);
    },
    getCompanyUsers({ dispatch }, id) {
        return services.getCompanyUsers(id);
    },
}
export default { module: {
    actions
} }