import state from './state'
import mutations from './mutations'
import * as actions from './actions'
import getters from './getters';
export default { module: {
	state,
	mutations,
	getters,
	actions,
	namespaced: true,
}}