
<template>
  <div v-show='isActive' class='tab'>
    <slot v-if="isLoaded"></slot>
  </div>
</template>

<script>
export default {
  inject: ['loadedTabs','currentName'],
  props: {
    label: {
      type: String,
      default: ''
    },
    lazy: {
    },
    name: {
      type : String,
      default:''
    },
    icon:{}
  },
  computed : {
    isLoaded() {
      return this.lazy === undefined ? true : (this.loadedTabs && this.loadedTabs.indexOf(this.name) >= 0)
    },
    isActive(){
      return this.name === this.currentName;
    }
  }
}
</script>

<style lang="css">

</style>