const loader = {
    namespaced: true,
    state: {
        loading: false,
        mloading:false//for manually loading loader
    },
    actions: {
        show({ commit }) {
            commit("show");
        },
        hide({ commit }) {
            commit("hide");
        },
        start({ commit }) {
            commit("start");
        },
        stop({ commit }) {
            commit("stop");
        }
    },
    mutations: {
        show(state) {
            state.loading = true;
        },
        hide(state) {
            state.loading = false;
        },
        start(state) {
            state.mloading = true;
        },
        stop(state) {
            state.mloading = false;
        }
    }
};
export default {
    module:loader
}