export const ADD_SIZE_TABLE_VALUES = 'app/sizetable/ADD_SIZE_TABLE_VALUES';
export const SIZE_TABLE_IMAGE = 'app/sizetable/SIZE_TABLE_IMAGE';
export const GENERATE_SIZE_TABLE_IMAGE = 'app/sizetable/GENERATE_SIZE_TABLE_IMAGE';
export const SET_KEYWORD = 'app/sizetable/SET_KEYWORD';
export const SET_RESULTS = 'app/sizetable/SET_RESULTS';
export const SET_PRODUCTS = 'app/sizetable/SET_PRODUCTS';
export const LOADING = 'app/sizetable/LOADING';
export const PREVIEW_LOADING = 'app/sizetable/PREVIEW_LOADING';
export const SAVED = 'app/sizetable/SAVED';
export const PREVIEW = 'app/sizetable/PREVIEW';
export const GENERATED = 'app/sizetable/GENERATED';
export const SEARCH_ATTRIBUTE_VALUES = 'app/sizetable/SEARCH_ATTRIBUTE_VALUES';