
export default {
  name: 'MappingTemplateSources',

  data() {
    return {
      link: {
        inbound: {
          name: 'products.mapping-template-inbound'
        },
        outbound: {
          name: 'products.mapping-template-outbound'
        }
      }
    }
  }
}
