import { localStorageGetItem } from './local'
import { cookies, local } from 'brownies'
import { isArray } from 'lodash'
import { ElNotification } from 'element-plus'
import { backendUrl } from '@/config/config'
import * as _ from 'lodash'

export function EcGetActiveCompanyId() {
  return localStorageGetItem('activeCompany')
}

export function EcGetCurrentUser() {
  return localStorageGetItem('user')
}

export function authToken(): any {
  return local.token || cookies.token
}

export function get422Errors(error): string | Array<string> {
  let messages: string | Array<string>
  try {
    const data = error.response.data
    if (data.errors) {
      messages = []
      const fields = Object.keys(data.errors)
      for (let i = 0; i < fields.length; i++) {
        for (let j = 0; j < data.errors[fields[i]].length; j++) {
          messages.push(data.errors[fields[i]][j])
        }
      }
    } else if (data.data) {
      messages = data.data.error
    } else {
      messages = data.message
    }
  } catch (ex) {
    messages = 'Something went wrong. Please contact admin.'
  }
  return messages
}
//export {get422Errors};

export function displayError(context, msg) {
  context.$message({
    showClose: true,
    message: msg,
    type: 'error',
  })
}

export function errorNotify(error) {
  //this might need to be handled differenty for graphql
  //todo
  if (typeof error.response === 'undefined') {
    if (error.code === 'ECONNABORTED') {
      ElNotification({
        title: 'Request Timed Out',
        message:
          'It is taking too long to complete this action. Please try again later.',
        type: 'error',
      })
      return
    }
    ElNotification({
      title: 'Connection Issue',
      message: 'Unable to connect with servers, Please try again.',
      type: 'error',
    })
    return
  }

  switch (error.response.status) {
  case 401:
    ElNotification({
      title: 'Unauthorized',
      type: 'error',
      message: error.response.data.message,
    }) //todo does message has data
    break

  case 403:
    ElNotification({
      title: 'Forbidden',
      type: 'error',
      message: 'You do not have access for this action.',
    })
    break

  case 404:
    ElNotification({
      title: 'Not Found',
      type: 'error',
      message: 'The server can not find the requested resource.',
    })
    break

  case 500:
    ElNotification({
      title: 'Erorr on our side',
      type: 'error',
      message: 'Internal server error',
    })
    break

  default:
    //todo: check if the error.response.data has acutal data
    if (isArray(error.response.data)) {
      ElNotification({
        title: 'Error',
        type: 'error',
        message: error.response.data.message,
      })
    } else {
      const messages = get422Errors(error)
      if (typeof messages == 'object') {
        messages.forEach((msg) => {
          ElNotification({
            title: 'Something went wrong',
            type: 'error',
            message: msg,
          })
        })
      } else {
        ElNotification({
          title: 'Something went wrong',
          type: 'error',
          message: messages,
        })
      }
    }
    break
  }
}

export function shouldRedirectToLogin(error, router) {
  if (error.response?.status === 401) {
    const current_route = router.currentRoute
    if (current_route && current_route.name == 'login.index') return false
    return true
  }
  return false
}

export function fallbackImgUrl() {
  return '/static/assets/images/default-profile-pic.png'
}

export function getProfilePictureUrl(user) {
  if (user && user.profile_pic) {
    return user.profile_pic.indexOf('http') === -1
      ? backendUrl.replace('api/v1/', '') + user.profile_pic
      : user.profile_pic
  }
  return ''
}

export function useDebounce() {
  let debounceTimeout = null

  return (handler, time = 200) => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(handler, time)
  }
}

export const toKebabCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-')
