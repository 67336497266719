<template>
  <div class="ec_tabs" :class="['is_'+position]">
    <div v-if="showTabsList" class="ec_tabs__header" :style="{ width: paneWidth+'%'}">
      <ul class='ec_tabs__header__list'>
        <li
v-for='(tab, index) in ec_tabs'
          :key='tab?.props?.name'
          :class='{"ec_tab__selected": (index == selectedIndex)}'
          @click='selectTab(index, true)'>
          <a @click.prevent>
            <i v-if="getIcon(tab)" class="ec_tab_icon" :class="getIcon(tab)"/>
            {{ tab?.props?.label }}
          </a>
        </li>
      </ul>
    </div>
    <div class="ec_tabs__content" :style="{ width: '100%'}">
      <slot></slot>
      <slot ref="tab_panes" name="content"></slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  provide() {
    return {
      loadedTabs: computed(() => this.loadedTabs),
      currentName: computed(() => this.currentName)
    }
  },
  props: {
    position : {
      type : String,
      default : 'left'
    },
    paneWidth: {
      type: String
    },
    contentWidth: {
      type: String
    },
    activeName: String,
    modelValue: {},
    oneTabHidden:String,
    iconed:{}
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      // ec_tabs: [],         // all of the ec_tabs
      currentName: this.modelValue || this.activeName,
      currentIndex:0,
      loadedTabs:[]
    }
  },
  computed : {
    showTabsList() {
      return !(this.oneTabHidden !== undefined && this.ec_tabs.length<2)
    },
    ec_tabs() {
      return this.$slots.default()[0]?.children || [];
    }
  },
  watch: {
    activeName(value) {
      this.setCurrentName(value);
    },
    modelValue(value) {
      this.setCurrentName(value);
    },
    currentName(value) {
      const activeIndex = this.getActiveIndex(value)

      if (activeIndex > -1) {
        this.selectTab(activeIndex)
        this.$emit('tabSelected',{index: activeIndex, name: this.currentName})
      }
    }
  },
  // created () {
  //   this.ec_tabs = this.$slots.default()[0].children;

  // },
  mounted () {
    const activeIndex = this.getActiveIndex(this.currentName)

    if (activeIndex > -1) {
      this.selectTab(activeIndex)
    }
  },
  methods: {
    selectTab (i, emit) {
      this.selectedIndex = i

      // loop over all the ec_tabs
      this.ec_tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
        if(index === i){
          let tabName =  tab?.props?.name;
          this.currentIndex = index
          this.setCurrentName(tabName)
          if(this.loadedTabs.indexOf(tabName) == -1 ) this.loadedTabs.push(tabName);
          if(emit)
            this.$emit('tabClicked', {index:  index, name:tabName})
        }
      })
    },
    next(){
      let nextIndex = this.currentIndex + 1
      if(nextIndex == this.ec_tabs.length) nextIndex = 0
      this.selectTab(nextIndex)
    },
    label(tab) {
      return tab?.props?.label?tab?.props?.label:tab.$slots.label[0].text
    },
    getIcon(tab) {
      if(this.iconed == undefined) return
      return tab?.props?.icon
    },
    setCurrentName(value) {
      this.currentName = value;
      this.$emit('update:modelValue', value);
    },
    getActiveIndex(name){
      let activeIndex = -1
      if(name == '' || name == undefined) {
        activeIndex = 0
      }
      else {
        this.ec_tabs.forEach((tab, index)=>{
          if(tab?.props?.name && tab?.props?.name == name) {
            activeIndex = index
          }
        })
        if(activeIndex == -1) activeIndex = 0
      }
      return activeIndex
    }
  }
}
</script>

<style lang="css">

  ul.ec_tabs__header__list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    border:solid 1px #dadada;
    padding:10px 0;
    border-radius: 7px;
  }
  .ec_tabs.is_left {
    display: flex;

  }


  .ec_tabs.is_left ul.ec_tabs__header__list > li {
    padding: 5px 0;
    display: block;
    cursor: pointer;
    width: 85%;
    margin: 8px auto;
    font-size: 14px;
  }
  ul.ec_tabs__header__list > li a{
        color:#c6cacf;
        padding: 0 12px;
        display: block;
        position: relative;

  }
  ul.ec_tabs__header__list >li.ec_tab__selected {
    border-bottom : 2px solid #249af3;
  }
  ul.ec_tabs__header__list >li.ec_tab__selected a:after {
    content: ' \25CF';
    font-size: 18px;
    color: #249af3;
    right:0;
    padding-left:12px;
    position: absolute;
    top:-2px;
  }
  ul.ec_tabs__header__list >li.ec_tab__selected a,ul.ec_tabs__header__list > li a:hover {
    color: #249af3;
  }



  .tab {
    display: inline-block;
    color: black;
    padding: 0 20px;
    border-radius: 10px;
    width: 100%;
  }

  .ec_tabs__light .tab{
    background-color: #fff;
  }

  .ec_tabs__light li {
    background-color: #ddd;
    color: #aaa;
  }

  .ec_tab_icon {
    padding-right: 8px;
  }



</style>