import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aed22cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mapping-tpl-manage-box" }
const _hoisted_2 = { class: "outbound-head-wrapper" }
const _hoisted_3 = { class: "outbound-breadcrumb" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "mapping-template-content" }
const _hoisted_7 = { class: "mapping-tpl-manage-main-outer" }
const _hoisted_8 = {
  key: 1,
  class: "mapping-tpl-manage-main"
}
const _hoisted_9 = { class: "mapping-fields-form-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_arrow_right = _resolveComponent("arrow-right")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_MappingTemplateManageHeader = _resolveComponent("MappingTemplateManageHeader")!
  const _component_CategoryMappingTemplate = _resolveComponent("CategoryMappingTemplate")!
  const _component_MappingFieldsForm = _resolveComponent("MappingFieldsForm")!
  const _component_product_category_selector = _resolveComponent("product-category-selector")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", null, [
          _createVNode(_component_router_link, { to: "/catalog/mapping-template/" }, {
            default: _withCtx(() => [
              _createTextVNode(" Mapping templates")
            ]),
            _: 1
          }),
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_arrow_right)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("h4", null, [
          _createVNode(_component_router_link, { to: `/catalog/mapping-template/inbound` }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$route.params.type) + " Templates ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_arrow_right)
            ]),
            _: 1
          })
        ]),
        (_ctx.$route.params.id)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_4, "Edit Template"))
          : (_openBlock(), _createElementBlock("h4", _hoisted_5, "Create New Template"))
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
      _createVNode(_component_MappingTemplateManageHeader, {
        heading: $options.heading,
        "selected-category": _ctx.selectedCategory,
        onCancel: $options.cancel,
        onSave: $options.onSaveTemplate
      }, null, 8, ["heading", "selected-category", "onCancel", "onSave"]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.category.id)
          ? (_openBlock(), _createBlock(_component_CategoryMappingTemplate, {
              key: 0,
              "show-actions-buttons": false,
              "category-name": _ctx.category?.name,
              "category-path": _ctx.category?.path
            }, null, 8, ["category-name", "category-path"]))
          : _createCommentVNode("", true),
        ($options.currentlyEditedTemplate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_MappingFieldsForm, {
                  category: _ctx.selectedCategory,
                  template: $options.currentlyEditedTemplate,
                  onNewMappingConfigured: $options.onNewMappingConfigured
                }, null, 8, ["category", "template", "onNewMappingConfigured"])
              ])
            ]))
          : (_openBlock(), _createBlock(_component_product_category_selector, {
              key: 2,
              "default-catalog-source-id": _ctx.mappingSourceMarket?.id,
              "default-catalog-source-marketplace-id": _ctx.mappingSourceMarketplace?.id,
              "hide-suggestion-tab": true,
              "default-active-tab": "browse_categories",
              class: "msg-info-box",
              onCategorySelected: _cache[0] || (_cache[0] = 
            (category,source_id, marketplace_id) => {
              $options.onCategorySelected({
                category_name: category.category_name,
                category_id: category.id,
                category_path: category.path_name,
                marketplace_id,
              })
            }
          )
            }, null, 8, ["default-catalog-source-id", "default-catalog-source-marketplace-id"]))
      ])
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}