import * as TYPES from '@/store/types'
import * as services from '../service/matchingProducts.services'

const state = {
  matchingProducts: [],
};

const mutations = {
  [TYPES.SET_MATCHING_PRODUCTS](_state, obj) {
    _state.matchingProducts = obj
  },
}

const actions = {
  getMatchingProducts({ dispatch }, ids) {
    return services.getMatchingProducts(ids).then((response) => {
      // dispatch('setFetching', { fetching: false })
      return response;
    })
  },

  getEbayMatchedProducts({ dispatch }, ids) {
    return services.getEbayMatchingProducts(ids).then((response) => {
      // dispatch('setFetching', { fetching: false })
      return response;
    })
  },

  setMatchingProducts({ commit }, obj) {
    commit(TYPES.SET_MATCHING_PRODUCTS, obj)
  },
}


export default { module: {
  state,
  mutations,
  actions
} }
