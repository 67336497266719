export default {
    products: (state, getters, rootState) => (
        state.selectedProducts
    ),

    isSingle: (state) => (
        state.selectedProducts.length === 1
    ),

    getSingleProduct: (state) => (
        state.selectedProducts[0]
    ),

    preview: (state) => (
        state.preview
    ),
};