// import { from, ApolloClient  } from '@apollo/client/core';
// import { setContext } from 'apollo-link-context'
// import { onError } from '@apollo/client/link/error'
// import {authToken, errorNotify, shouldRedirectToLogin } from '@/utils/helpers'
// import { graphqlBaseUrl} from '@/config/config';

// //fix for 'regeneratorRuntime is not defined' error in firefox and other browsers
// import 'regenerator-runtime';
// let _router
// let _dispatch

// export function createApolloProvider({ dispatch }, router) {
//   _router = router
//   _dispatch = dispatch
//   return createProvider()
// }

// const errorHandler = onError(({ graphQLErrors, networkError }) => {
//   let redirectingToLogin = false;
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       ),
//     )

//   if (networkError) {
//     if(shouldRedirectToLogin(networkError, _router)) {
//       _dispatch('signOut')
//       _router.push('/login');
//       redirectingToLogin = true
//       return Promise.reject(networkError)
//     }
//     else {
//       if(!redirectingToLogin) errorNotify(networkError)
//     }
//   }
// })

// // Name of the localStorage item
// const authLink = setContext(async (_, { headers }) => {
//   // Return the headers to the context so httpLink can read them
//   let token = authToken()
//   return {
//     headers: {
//       ...headers,
//       authorization: token || ''
//     }
//   }
// })
// // Http endpoint
// const httpEndpoint = graphqlBaseUrl

// // Config
// const defaultOptions = {
//   // You can use `https` for secure connection (recommended in production)
//   httpEndpoint,
//   // You can use `wss` for secure connection (recommended in production)
//   // Use `null` to disable subscriptions
//   // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
//   wsEndpoint:null,
//   // LocalStorage token
//   //tokenName: AUTH_TOKEN,
//   // Enable Automatic Query persisting with Apollo Engine
//   persisting: false,
//   // Use websockets for everything (no HTTP)
//   // You need to pass a `wsEndpoint` for this to work
//   websocketsOnly: false,
//   // Is being rendered on the server?
//   ssr: false,

//   // Override default apollo link
//   // note: don't override httpLink here, specify httpLink options in the
//   // httpLinkOptions property of defaultOptions.
//   link: from([errorHandler, authLink]),

//   cache:null,

//   // Override default cache
//   // cache: myCache

//   // Override the way the Authorization header is set
//   // getAuth: (tokenName) => ...

//   // Additional ApolloClient options
//   // apollo: { ... }

//   // Client local data (see apollo-link-state)
//   // clientState: { resolvers: { ... }, defaults: { ... } }
// }

// // const { apolloClient, wsClient } = createApolloClient({
// //   ...defaultOptions
// // })

// export {apolloClient as gclient};
// // Call this in the Vue app file
// function createProvider (options = {}) {
//   // Create apollo client

//   apolloClient.wsClient = wsClient

//   // Create vue apollo provider
//   const apolloProvider = new ApolloClient({
//     defaultClient: apolloClient
//   })

//   return apolloProvider
// }

// // Create the apollo client
// export const apolloClient = new ApolloClient({
//   link: graphqlBaseUrl,
//   connectToDevTools: true,
// })



import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import {authToken, errorNotify, shouldRedirectToLogin } from '@/utils/helpers'
import { graphqlBaseUrl} from '@/config/config';
import store from '@/store/index';
import router from '@/router/index';

function getHeaders() {
  const token = authToken()
  return {
    authorization: token || ''
  }
}

// Create an http link:
const httpLink = new HttpLink({
  uri: graphqlBaseUrl,
  fetch: (uri: RequestInfo, options: RequestInit) => {
    options.headers = {...options.headers, ...getHeaders()};
    return fetch(uri, options)
  },
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  let redirectingToLogin = false;
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )

  if (networkError) {
    if(shouldRedirectToLogin(networkError, router)) {
      store.dispatch('signOut')
      router.push('/login');
      redirectingToLogin = true
      Promise.reject(networkError)
    }
    else {
      if(!redirectingToLogin) errorNotify(networkError)
    }
  }
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(httpLink)
})