import { http } from '@/utils/plugins/http'

export const login = obj => http.post('users/sign_in', obj)

export const socialLogin = ({ platform, payload }) => http.post(`users/auth/${platform}/callback`, payload)

export const forgotPassword = obj => http.post('users/forgot_password', obj)

export const resetPassword = obj => http.post('users/reset_password', obj)

export const signOut = obj=> http.delete('users/sign_out', {data: obj});

export const register = obj => http.post('users/sign_up', obj)

export const inviteRegister = obj => http.put('users/invitation', obj)

export const checkUsername = obj => http.post('users/check_username', obj)
