import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryRenderer = _resolveComponent("CategoryRenderer")!

  return (_openBlock(), _createBlock(_component_CategoryRenderer, {
    categories: $props.topCategories,
    loading: false,
    onCategorySelected: $options.categorySelected
  }, null, 8, ["categories", "onCategorySelected"]))
}