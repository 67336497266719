import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6d057b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "sidebar-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SidebarToggler = _resolveComponent("SidebarToggler")!
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_SidebarProfile = _resolveComponent("SidebarProfile")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`sidebar-wrapper ${$data.drawer ? 'mini-collapse--open':''}`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["logo-wrapper", {'mini':$data.drawer}])
    }, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/logos/ecompim'+($data.drawer?'-mini':'')+'-logo.svg'),
            alt: "eComPIM - Logo"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_SidebarToggler, { onDrawerToggled: $options.drawerToggled }, null, 8, ["onDrawerToggled"])
    ], 2),
    _createElementVNode("div", {
      id: "menu-wrapper",
      class: _normalizeClass(`menu-wrapper ${$data.drawer ? 'menu-open':'menu-close'}`)
    }, [
      _createVNode(_component_el_menu, {
        class: "el-menu-vertical-demo",
        "default-openeds": $data.openSubMenus,
        "default-active": $data.activeMenu,
        "unique-opened": true,
        onOpen: $options.scrollUpOpenMenus,
        onSelect: $options.handleMenuOpen
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SidebarMenu, {
            menus: $data.items,
            drawer: $data.drawer
          }, null, 8, ["menus", "drawer"])
        ]),
        _: 1
      }, 8, ["default-openeds", "default-active", "onOpen", "onSelect"])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SidebarProfile, { drawer: $data.drawer }, null, 8, ["drawer"])
    ])
  ], 2))
}