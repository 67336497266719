
import CategoryRenderer from './../components/CategoryRenderer.vue'
export default {
  components:{
    CategoryRenderer
  },
  props:{
    topCategories: {
      type: Array,
      default: ()=>[]
    }
  },
  data(){
    return {
    }
  },
  methods:{
    categorySelected(category){
      this.$emit('categorySelected', category)
    }
  }

}
